import { format } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactDatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { SpinnerCircular } from "spinners-react";
import ItemCard from "../../components/ItemCard";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import PackingSlipPrint from "../../components/PrintTemplates/PackingSlipPrint";
import ReceiptPrint from "../../components/PrintTemplates/ReceiptPrint";
import DataCache from "../../utilities/DataCache";
import PostLabel from "../../components/PrintTemplates/PostLabel";
import PickList from '../PickList/PickList';
registerLocale('en-GB', enGB);
setDefaultLocale('en-GB');

function OrderManagementPanel({ onSuccess, heading = false, hideToolbar = false }) {
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [orders, setOrders] = useState(null);
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState([]);
    const [printSelection, setPrintSelection] = useState("");
    const [waiting, setWaiting] = useState(false);
    const [settings, setSettings] = useState(null);
    const [ordersToPrint, setOrdersToPrint] = useState();
    const loader = useRef(null);

    const [pickList, setPickList] = useState(false);

    // Filters
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [status, setStatus] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [typeText, setTypeText] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();

    // Print handling code
    const [isPrinting, setIsPrinting] = useState(false);
    const printingRef = useRef(null);

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => printingRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        }
    });

    const clearFilters = () => {
        setSearchText("");
        setTypeText("");
        setFromDate(null);
        setToDate(null);
        setStatus("");
        setPaymentType("");
        setSelected([]);
        getData(true, { TextSearch: "", fromDate: null, toDate: null, status: "", type: "", paymentType: "" });
    }

    const getData = (clear = false, filterOverride = null) => {
        setLoading(true);

        var query = {
            count: 50
        }

        if (!clear && orders && orders.length > 0) {
            query.lastDate = orders[orders.length - 1].createdAt;
        }

        if (searchText.length > 0) {
            query.TextSearch = searchText;
        }

        if (typeText.length > 0) {
            query.type = typeText;
        }

        if (fromDate) {
            query.fromDate = fromDate.toISOString();
        }

        if (toDate) {
            query.toDate = toDate.toISOString();
        }

        if (status.length > 0) {
            query.status = status;
        }

        if (paymentType.length > 0) {
            query.paymentType = paymentType;
        }

        if (filterOverride) {
            query = { ...query, ...filterOverride };
        }

        if (query.fromDate === null) delete query.fromDate
        if (query.toDate === null) delete query.toDate

        APIFetch("POST", "order/search", query)
            .then(result => {
                if (result.ok) {
                    if (clear || !orders) {
                        setOrders(result.data);
                    } else {
                        setOrders(orders.concat(result.data));
                    }

                    if (clear) {
                        setSelected([]);
                    }

                    if (result.data.length < query.count) {
                        setComplete(true);
                    } else {
                        setComplete(false);
                    }
                } else {
                    setOrders([]);
                    setComplete(true);
                    setMessage("An error occurred loading orders.");
                }
                setLoading(false);
            })
            .catch(() => {
                setOrders([]);
                setComplete(true);
                setLoading(false);
                setMessage("An error occurred loading orders.");
            })
    }

    useEffect(() => {
        var param = searchParams.get("search");
        setSearchText(param || "");

        var typeParam = searchParams.get("type");
        setTypeText(typeParam || "");

        var statusParam = searchParams.get("status");
        setStatus(statusParam || "");

        setSelected([]);

        var overrideObject = {};

        if (param) {
            overrideObject.TextSearch = param;
        }

        if (typeParam) {
            overrideObject.type = typeParam;
        }

        if (statusParam) {
            overrideObject.status = statusParam;
        }

        getData(true, overrideObject);

        APIFetch("GET", 'settings')
            .then(result => {
                if (result.ok) {
                    setSettings([...result.data]);
                } else {
                    setMessage("An error occurred when attempting to load system settings.");
                }
            })
            .catch(e => {
                setMessage("An error occurred when attempting to load system settings.");
            })
    }, [searchParams]);

    const detectEnter = ({ key }) => {
        if (key === "Enter") {
            if (searchParams.get("search")) {
                searchParams.delete("search");
                setSearchParams(searchParams);
            } else {
                getData(true);
            }

            setSelected([]);
        }
    }

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (!loading && !complete && target.isIntersecting) {
            getData();
        }
    }, [complete, loading, getData]);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };

        const observer = new IntersectionObserver(handleObserver, option);
        const current = loader.current;

        if (current) observer.observe(current);

        return () => {
            if (current) observer.unobserve(current);
        }
    }, [complete, loading, handleObserver]);

    const toggleRow = (stock, value) => {
        if (value) {
            setSelected([...selected, stock]);
        } else {
            setSelected(selected.filter(s => s !== stock));
        }
    }

    const setAsProcessing = () => {
        var completed = false;
        if (!selected.some(o => o.status !== "Processing")) {
            // All are processing so mark as completed
            completed = true;
        } else if (selected.some(o => o.status !== "Fully Paid")) {
            setMessage("Only orders that are currently 'Fully Paid' can be marked as processing.");
        }

        if (!waiting) {
            setWaiting(true);

            // Set selected orders to processing
            APIFetch('POST', completed ? 'order/complete' : 'order/process', selected.map(o => o.id))
                .then(result => {
                    if (result.ok) {
                        // Update local data
                        var newOrders = [...orders];
                        newOrders.forEach(o => {
                            if (selected.some(no => o.id === no.id)) {
                                if (completed) {
                                    o.status = "Completed";
                                } else if (o.status === "Fully Paid") {
                                    o.status = "Processing";
                                }
                            }
                        });
                        setOrders(newOrders);
                        setMessage("Order statuses updated.");
                    } else {
                        setMessage("Failed to update order statuses.");
                    }
                })
                .catch(result => {
                    setMessage("Failed to update order statuses.");
                });
        }
    }

    const triggerPrint = () => {
        if (printSelection === "") return;
        setWaiting(true);

        // Get orders by ID list
        APIFetch('POST', 'order/retrieve', selected.map(o => o.id))
            .then(result => {
                if (result.ok) {
                    setOrdersToPrint(result.data);
                    handlePrint();
                } else {
                    setMessage("Failed to load order details to print.");
                }
            })
            .catch(result => {
                setMessage("Failed to load order details to print.");
            });
    }

    if (pickList) {
        return <PickList orderIds={selected.filter(s => orders.find(o => o.id === s.id).status !== 'Completed').map(s => s.id)} onClose={() => setPickList(false)} />;
    }

    // Name search, VAT status, from/to
    return <div className="max-h-full min-h-0">
        <MessageModal message={message} onClose={() => setMessage(null)} />
        {isPrinting ? <div ref={printingRef} className="onlyPrint">{ordersToPrint.map(o => {
            if (printSelection === "Packing Slips") {
                var discounts = [];
                var itemDiscounts = {};

                o.discounts.forEach(d => {
                    var newItem = {
                        id: d.id,
                        type: d.flatDiscount > 0 ? 'fixed' : 'percentage',
                        amount: d.flatDiscount || d.percentageDiscount,
                        currentValue: d.currentValue,
                        discount: d.discount
                    };

                    if (d.orderItemId && d.orderItemId > 0) {
                        if (itemDiscounts[d.orderItemId]) {
                            itemDiscounts[d.orderItemId].push(newItem);
                        } else {
                            itemDiscounts[d.orderItemId] = [newItem];
                        }
                    } else {
                        discounts.push(newItem)
                    }
                });

                return <PackingSlipPrint
                    order={o}
                    discounts={discounts}
                    itemDiscounts={itemDiscounts}
                    vouchers={o.vouchers}
                    VATRate={!o.vatPayable ? 1 : DataCache.vatRate}
                    settings={settings}
                    multiPrint={true} />
            } else if (printSelection === "Receipts") {
                var discounts = [];
                var itemDiscounts = {};

                o.discounts.forEach(d => {
                    var newItem = {
                        id: d.id,
                        type: d.flatDiscount > 0 ? 'fixed' : 'percentage',
                        amount: d.flatDiscount || d.percentageDiscount,
                        currentValue: d.currentValue,
                        discount: d.discount
                    };

                    if (d.orderItemId && d.orderItemId > 0) {
                        if (itemDiscounts[d.orderItemId]) {
                            itemDiscounts[d.orderItemId].push(newItem);
                        } else {
                            itemDiscounts[d.orderItemId] = [newItem];
                        }
                    } else {
                        discounts.push(newItem)
                    }
                });

                return <ReceiptPrint
                    order={o}
                    discounts={discounts}
                    itemDiscounts={itemDiscounts}
                    vouchers={o.vouchers}
                    VATRate={!o.vatPayable ? 1 : DataCache.vatRate}
                    settings={settings}
                />
            } else {
                var address = o.deliveryAddressId || o.billingAddressId ? o.customer.addresses.find(a => a.id === (o.deliveryAddressId || o.billingAddressId)) : null;
                if (address !== null) {
                    return <PostLabel addressLines={[address.name, address.address1, address.address2, address.city, address.postcode, address.country]} multiPrint={true} />;
                } else {
                    return <PostLabel addressLines={["No customer address provided."]} multiPrint={true} />;
                }
            }
        })}</div> : null}
        <div className="flex flex-col max-h-full">
            {!hideToolbar ? <div className={heading ? "flex flex-row items-end ml-2 mt-9 mb-12 flex-wrap text-left" : "flex flex-row items-end flex-wrap text-left"}>
                {heading ? <h1 className="text-left font-medium text-lg mr-8 self-center">Orders</h1> : null}
                <div className="flex flex-col mb-3">
                    <div className="text-sm text-brand-grey">No. / Customer</div>
                    <input type="text"
                        value={searchText}
                        className={"text-sm w-[180px] mr-6"}
                        onChange={(e) => { setSearchText(e.target.value) }} placeholder="Search..."
                        onKeyDown={detectEnter} />
                </div>
                <select className={"mr-6 mb-3 text-sm text-brand-grey"} value={typeText} onChange={(e) => { setTypeText(e.target.value); getData(true, { type: e.target.value }); }}>
                    <option value={""}>Any Type</option>
                    <option value={"EPOS"}>EPOS</option>
                    <option value={"Web"}>Web</option>
                </select>
                <select className={"mr-6 mb-3 text-sm text-brand-grey"} value={status} onChange={(e) => { setStatus(e.target.value); getData(true, { status: e.target.value }); }}>
                    <option value={""}>Any Status</option>
                    <option value={"New"}>New</option>
                    <option value={"Deposit Paid"}>Deposit Paid</option>
                    <option value={"Awaiting Stock"}>Awaiting Stock</option>
                    <option value={"Fully Paid"}>Fully Paid</option>
                    <option value={"Processing"}>Processing</option>
                    <option value={"Completed"}>Completed</option>
                </select>
                <div className="flex flex-row mb-3">
                    <select className={"mr-6 text-sm text-brand-grey"} value={paymentType} onChange={(e) => { setPaymentType(e.target.value); getData(true, { paymentType: e.target.value }); }}>
                        <option value={""}>Any Last Payment</option>
                        <option value={"Card"}>Card</option>
                        <option value={"Cash"}>Cash</option>
                        <option value={"Cheque"}>Cheque</option>
                        <option value={"Credit Note"}>Credit Note</option>
                        <option value={"Loyalty Points"}>Loyalty Points</option>
                        <option value={"Refund"}>Refund</option>
                        <option value={"Voucher"}>Voucher</option>
                    </select>
                </div>
                <div className="flex flex-col mb-3 mr-6">
                    <div className="text-sm text-brand-grey">From</div>
                    <ReactDatePicker onChange={(e) => { setFromDate(e); getData(true, { fromDate: e ? e.toISOString() : null }) }} selected={fromDate} dateFormat="P" placeholderText="dd/mm/yyyy" />
                </div>
                <div className="flex flex-col mb-3 mr-8">
                    <div className="text-sm text-brand-grey">To</div>
                    <ReactDatePicker onChange={(e) => { setToDate(e); getData(true, { toDate: e ? e.toISOString() : null }) }} selected={toDate} dateFormat="P" placeholderText="dd/mm/yyyy" />
                </div>
                <div className="cursor-pointer text-sm border-b border-b-brand-grey mt-1 mb-3 text-brand-grey" onClick={clearFilters}>Clear All</div>
            </div> : null}

                <div className="min-w-[1000px] md:min-w-[0px] flex flex-col mt-8 text-brand-grey min-h-0">
                    <div className="flex flex-row px-3 font-bold mb-2 text-sm mt-2 text-left">
                        <div className="flex flex-1 basis-[50px]">
                            <input type="checkbox" checked={orders && orders.length === selected.length} onClick={(e) => { e.stopPropagation() }} onChange={(e) => { if (orders.length === selected.length) { setSelected([]) } else { setSelected(orders) } }} className=""></input>
                        </div>
                        <div className="flex flex-1 basis-1/12">No.</div>
                        <div className="flex flex-1 basis-2/12">Customer</div>
                        <div className="flex flex-1 basis-1/12">Total</div>
                        <div className="flex flex-1 basis-2/12">Date</div>
                        <div className="flex flex-1 basis-1/12">Time</div>
                        <div className="flex flex-1 basis-1/12">Type</div>
                        <div className="flex flex-1 basis-2/12">Status</div>
                        <div className="flex flex-1 basis-2/12">Last Payment</div>
                        <div className="flex flex-1 basis-2/12">Shipping</div>
                        {/* <div className="flex flex-1 basis-1/12">Delivery Country</div> */}
                    </div>
                    <div className="flex flex-col flex-grow overflow-y-auto">
                        {orders && orders.length === 0 ? <div>No orders to display.</div> : null}
                        {orders ? orders.map(o => <ItemCard
                            key={o.id} item={o}
                            onClick={() => { onSuccess(o.id) }}
                            additionalFields={[
                                {
                                    component: <div key={o.id + "selector"} className={"justify-center basis-[50px]"}>
                                        <input type="checkbox" checked={selected.includes(o)} onClick={(e) => { e.stopPropagation() }} onChange={(e) => { toggleRow(o, e.target.checked) }} className=""></input>
                                    </div>
                                },
                                { value: o.id, className: "basis-1/12" },
                                { value: o.customer, className: "basis-2/12" + (o.customerId ? " underline" : ""), onClick: (e) => { if (o.customerId) { e.stopPropagation(); navigate(`/orders?search=customer:${o.customerId}`) } } },
                                { value: '£' + o.total, className: "basis-1/12" },
                                { value: format(new Date(o.createdAt), "eee, MMM do"), className: "basis-2/12" },
                                { value: format(new Date(o.createdAt), "HH:mm"), className: "basis-1/12" },
                                { value: o.type, className: "basis-1/12" },
                                { value: o.status, className: "basis-2/12" },
                                { value: o.lastPaymentType || "-", className: "basis-2/12" },
                                { value: o.shippingMethod, className: "basis-2/12" },
                                // { value: o.deliveryCountry, className: "basis-1/12"}
                            ]} />) : null}
                        <div ref={loader} className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                            <SpinnerCircular enabled={!orders || !complete} size={50} color="#24272b" secondaryColor="white" />
                        </div>
                    </div>
                    {selected.length > 0 ? <div className="relative w-full bg-white bottom-[-20px] left-0 flex flex-row pb-2 pt-0 items-center justify-center">
                        <div className="font-medium">{selected.length} orders selected</div>
                        <div className="flex btn btn-sm px-2 ml-5 mr-5" onClick={() => { setAsProcessing() }}>
                            {selected.some(s => s.status !== "Processing") ? "Set As Processing" : "Set As Completed"}
                        </div>
                        <div className="flex btn btn-sm px-2" onClick={() => { setPickList(true) }}>
                            Create Pick List
                        </div>
                        <select className={"ml-10 text-sm text-brand-grey"} value={printSelection} onChange={(e) => { setPrintSelection(e.target.value) }}>
                            <option value="" disabled selected>Print type...</option>
                            <option value={"Labels"}>Labels</option>
                            <option value={"Packing Slips"}>Packing Slips</option>
                            <option value={"Receipts"}>Receipts</option>
                        </select>
                        <div className="flex btn btn-sm px-2 ml-2" onClick={() => { triggerPrint() }}>
                            Print
                        </div>
                    </div> : null}
                </div>


        </div>
    </div>;
}

export default OrderManagementPanel;