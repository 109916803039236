import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import DataCache from "../../utilities/DataCache";
import { SpinnerCircular } from "spinners-react";

function StockValue() {
    const [message, setMessage] = useState(null);
    const [stockData, setStockData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [locationCosts, setlocationCosts] = useState(null);
    const [shopId, setShopId] = useState(0);
    const [warehouseId, setWarehouseId] = useState(0);

    useEffect(() => {
        var warehouse = DataCache.location.find(l => l.name === "Warehouse");
        var shop = DataCache.location.find(l => l.name === "Shop");

        if(warehouse) { setWarehouseId(warehouse.id) }
        if(shop) { setShopId(shop.id) }

        APIFetch("GET", "stockvalue")
        .then(result => {
            if(result.ok) {
                var locationTotalCost = {};

                result.data.departmentData.forEach(d => {
                    d.locationData.forEach(l => {
                        if(!locationTotalCost[l.location]) locationTotalCost[l.location] = 0;
                        locationTotalCost[l.location] += l.cost;
                    })
                });

                setlocationCosts(locationTotalCost);
                setStockData(result.data);
            }

            setLoading(false);
        })
        .catch(() => {
            setLoading(false);
        })
    }, []);

    return (
        <div className="App">
            <MessageModal message={message} onClose={() => setMessage(null)} />
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="content overflow-y-scroll flex flex-col p-8 flex-grow text-left">
                    <div className="flex flex-row items-center ml-2 mt-9 mb-16">
                        <h1 className="text-left font-medium text-lg">Stock Value</h1>
                        <div className="flex flex-row items-center ml-auto border-r border-brand-grey px-4"><h2 className="mr-2">Items of Stock:</h2><h2>{stockData ? stockData.totalQuantity : 0}</h2></div>
                        <div className="flex flex-row items-center border-r border-brand-grey px-4"><h2 className="mr-2">Cost:</h2><h2>£{stockData ? stockData.totalCost.toFixed(2) : "0.00"}</h2></div>
                        <div className="flex flex-row items-center border-r border-brand-grey px-4"><h2 className="mr-2">Shop:</h2><h2>£{(locationCosts ? locationCosts[shopId] || 0 : 0).toFixed(2)}</h2></div>
                        <div className="flex flex-row items-center px-4"><h2 className="mr-2">Warehouse:</h2><h2>£{(locationCosts ? locationCosts[warehouseId] || 0 : 0).toFixed(2)}</h2></div>
                    </div>
                    <div className="flex flex-col flex-grow overflow-y-auto p-0">
                    {loading ? <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                            <SpinnerCircular size={50} color="#24272b" secondaryColor="white" />
                        </div> : null }
                        { !loading && !stockData ? <div>No stock to display.</div> : null }
                        { !loading && stockData ? <div className=" text-left grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1fr]">
                            <div className="p-2 border-b font-bold border-brand-grey sticky top-0 bg-white">Department</div>
                            <div className="p-2 border-b font-bold border-brand-grey sticky top-0 bg-white">Shop Quantity</div>
                            <div className="p-2 border-b font-bold border-brand-grey sticky top-0 bg-white">Warehouse Quantity</div>
                            <div className="p-2 border-b font-bold border-brand-grey sticky top-0 bg-white">Total Quantity</div>
                            <div className="p-2 border-b font-bold border-brand-grey sticky top-0 bg-white">Shop Cost</div>
                            <div className="p-2 border-b font-bold border-brand-grey sticky top-0 bg-white">Warehouse Cost</div>
                            <div className="p-2 border-b font-bold border-brand-grey sticky top-0 bg-white">Total Cost</div>
                            {
                                stockData.departmentData.map(s => {
                                    let shopData = s.locationData.find(l => l.location == shopId);
                                    let warehouseData = s.locationData.find(l => l.location == warehouseId);
                                    
                                    return [
                                        <div key={s.id + "department"} className="p-2 border-b border-brand-grey font-medium">{DataCache.departmentLookup[s.department].name}</div>,
                                        <div key={s.id + "shopquantity"} className="p-2 border-b border-brand-grey">{shopData ? shopData.quantity || 0 : 0}</div>,
                                        <div key={s.id + "warehousequantity"} className="p-2 border-b border-brand-grey">{warehouseData ? warehouseData.quantity || 0 : 0}</div>,
                                        <div key={s.id + "totalquantity"} className="p-2 border-b border-brand-grey">{s.quantity}</div>,
                                        <div key={s.id + "shopcost"} className="p-2 border-b border-brand-grey">£{shopData ? shopData.cost.toFixed(2) : "0.00"}</div>,
                                        <div key={s.id + "warehousecost"} className="p-2 border-b border-brand-grey">£{warehouseData ? warehouseData.cost.toFixed(2) : "0.00"}</div>,
                                        <div key={s.id + "totalcost"} className="p-2 border-b border-brand-grey">£{s.cost.toFixed(2)}</div>
                                    ]
                                })
                            }
                        </div> : null }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StockValue;