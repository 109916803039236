import { useCallback, useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import Sidebar from "../../components/Sidebar/Sidebar";
import { SpinnerCircular } from "spinners-react";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import DataCache from "../../utilities/DataCache";
import { useReactToPrint } from "react-to-print";

function Vouchers() {
    const [vouchers, setVouchers] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [complete, setComplete] = useState(false);
    const loader = useRef(null);
    const [selected, setSelected] = useState([]);

    // Filters
    const [searchText, setSearchText] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [departmentFilter, setDepartmentFilter] = useState("");

    useEffect(() => {
        applySearch({}, true);
    }, []);

    const applySearch = (filterOverride, clear = false) => {
        setLoading(true);
        var query = { count: 50 };

        if(vouchers && vouchers.length > 0) {
            query.lastDate = vouchers[vouchers.length - 1].updatedAt;
        }

        if(searchText.length > 0) {
            query.TextSearch = searchText;
        }

        if(filterOverride) {
            query = {...query, ...filterOverride};
        }

        APIFetch("POST", "voucher/report", query)
        .then(result => {
            if(result.ok) {
                if(clear || !vouchers) {
                    setVouchers(result.data);
                } else {
                    setVouchers(vouchers.concat(result.data));
                }

                if(result.data.length < query.count) {
                    setComplete(true);
                } else {
                    setComplete(false);
                }
            } else {
                setVouchers([]);
                setMessage("An error occurred when attempting to load the vouchers data");
                setComplete(true);
            }

            setLoading(false);
        })
        .catch(() => {
            setVouchers([]);
            setMessage("An error occurred when attempting to load the vouchers data");
            setLoading(false);
            setComplete(true);
        })
    }

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (!loading  && !complete && target.isIntersecting) {
          applySearch();
        }
      }, [complete, loading, applySearch]);

    useEffect(() => {
        const option = {
          root: null,
          rootMargin: "20px",
          threshold: 0
        };

        const observer = new IntersectionObserver(handleObserver, option);
        const current = loader.current;
  
        if (current) observer.observe(current);
    
        return () => {
          if (current) observer.unobserve(current);
        }
    }, [complete, loading, handleObserver]);

    const detectEnter = ({key}) => {
        if(key === "Enter") {
            applySearch({}, true);
        }
    }

    const clearFilters = () => {
        if(searchText === "") return;
        setSearchText("");
        applySearch({ categoryId: null, locationId: null, departmentId: null, TextSearch: "" }, true);
    }

    const toggleRow = (vouchers, value) => {
        if(value) {
            setSelected([...selected, vouchers]);
        } else {
            setSelected(selected.filter(v => v !== vouchers));
        }
    }

    return (
        <div className="App">
            <MessageModal message={message} onClose={() => setMessage(null)} />
            <div className="flex flex-row w-full h-full overflow-y-hidden">
                <Sidebar />
                <div className="relative content flex flex-col p-8 pb-4 flex-grow">
                    <div className="flex flex-row items-center ml-2 mt-9 mb-16 flex-wrap">
                        <h1 className="text-left font-medium text-lg">Vouchers</h1>
                        <input type="text"
                            value={searchText} 
                            className="ml-10 mb-1 text-sm" 
                            onChange={(e) => { setSearchText(e.target.value) }} placeholder="Search..."
                            onKeyDown={detectEnter}/>
                        <div className="cursor-pointer ml-10 text-sm border-b border-b-brand-grey mt-1 mr-4" onClick={clearFilters}>Clear All</div>
                    </div>
                    <div className="flex flex-col flex-grow overflow-y-auto">
                        {loading ? <div className="w-full flex flex-grow flex-shrink flex-row justify-center items-center mb-6 mt-2">
                            <SpinnerCircular size={50} color="#24272b" secondaryColor="white" />
                        </div> : null }
                        { !loading && vouchers && vouchers.length === 0 ? <div>No vouchers to display.</div> : null }
                        { vouchers && vouchers.length > 0 ? <div className=" text-left grid grid-cols-[auto_auto_auto_auto_auto_auto_120px_120px]">
                            <div className="p-3 font-bold sticky top-0 bg-white"></div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Order ID</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Code</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Value</div>
                            <div className="p-3 font-bold sticky top-0 bg-white text-center">Created At</div>
                            <div className="p-3 font-bold sticky top-0 bg-white text-center">Expires</div>
                            <div className="p-3 font-bold sticky top-0 bg-white text-center">Purchased</div>
                            <div className="p-3 font-bold sticky top-0 bg-white">Void</div>
                            {
                                vouchers.map(v => {
                                    let bgClass = "flex p-3 border-b border-brand-grey text-sm font-medium";

                                    return [
                                        <div key={v.id + "selector"} className={"justify-center " + bgClass}>
                                            <input type="checkbox" checked={selected.includes(v)} onChange={(e) => { toggleRow(v, e.target.checked)}} className=""></input>
                                        </div>,
                                        <div key={v.id + "orderid"} className={"items-center " + bgClass}>{v.orderId}</div>,
                                        <div key={v.id + "code"} className={"underline items-center " + bgClass}>{v.code}</div>,
                                        <div key={v.id + "value"} className={"items-center " + bgClass}>£{v.currentValue ? v.currentValue.toFixed(2) : '0.00'}</div>,
                                        <div key={v.id + "createdat"} className={"items-center " + bgClass}>{v.createdAt ? format(new Date(v.createdAt), "eee, MMM do HH:mmaaa") : null}</div>,
                                        <div key={v.id + "expires"} className={"items-center " + bgClass}>{v.expires ? format(new Date(v.expires), "eee, MMM do HH:mmaaa") : null}</div>,
                                        <div key={v.id + "purchased"} className={"items-center " + bgClass}>{v.purchased ? 'TRUE' : 'FALSE'}</div>,
                                        <div key={v.id + "void"} className={"items-center " + bgClass}>{v.void ? 'TRUE' : 'FALSE'}</div>
                                    ]
                                })
                            }
                        </div> : null }
                    </div>
                    <div className="w-full bg-white flex flex-row p-4 pb-0 items-center justify-center">
                        {/* {selected && selected.length > 0 ? <div className="flex btn px-2 ml-5">
                            Transfer {selected.length} Variants
                        </div> : null } */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vouchers;