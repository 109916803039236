import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import textLogo from '../../assets/images/chatleystext.png';
import MessageModal from "../../components/MessageModal";
import Modal from "../../components/Modal";
import { AuthStore } from "../../stores/AuthStore";
import APIFetch from "../../utilities/APIFetch";
import DataCache from "../../utilities/DataCache";
import ProductManagement from "../ProductManagement/ProductManagement";
import TillAddressBlock from "./TillAddressBlock";
import TillAdjustmentModal from "./TillAdjustmentModal";
import TillCustomerModal from "./TillCustomerModal";
import TillDiscountModal from "./TillDiscountModal";
import TillNoteModal from "./TillNoteModal";
import TillOrderLookupModal from "./TillOrderLookupModal";
import TillPaymentModal from "./TillPaymentModal";
import TillUserModal from "./TillUserModal";
import TillVariantSelector from "./TillVariantSelector";
import TillVoucherModal from "./TillVoucherModal";
import OverlaySpinner from "../../components/OverlaySpinner";
import { useReactToPrint } from "react-to-print";
import ReceiptPrint from "../../components/PrintTemplates/ReceiptPrint";
import OrderDetails from "./OrderDetails";
import PostLabel from "../../components/PrintTemplates/PostLabel";
import PackingSlipPrint from "../../components/PrintTemplates/PackingSlipPrint";
import TillAdjustmentPrintModal from "./TillAdjustmentPrintModal";
import TillCreditNoteModal from "./TillCreditNoteModal";
import TillReturnModal from "./TillReturnModal";
import TillShipmentModal from "./TillShipmentModal";
import TillCustomerSourceModal from "./TillCustomerSourceModal";
import TillRetroactivePointsModal from "./TillRetroactivePointsModal";
import TillConfirmCompletionModal from "./TillConfimCompletionModal";

const get = (obj, property) => {
    if(!obj) return null;
    return obj[property];
}

const Till = () => {
    let { tillId, orderId } = useParams();

    const [currentUser, setCurrentUser] = useState(null);
    const [order, setOrder] = useState(null);
    const [adjusting, setAdjusting] = useState(false);
    const [printingAdjustment, setPrintingAdjustment] = useState(false);
    const [switching, setSwitching] = useState(false);
    const [selectingProduct, setSelectingProduct] = useState(false);
    const [selectingVariant, setSelectingVariant] = useState(null);
    const [till, setTill] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const backUrl = location.state.backUrl;
    const [departments, setDepartments] = useState([...DataCache.department].sort((a, b) => {
        if(a.openOnly !== b.openOnly) return Number(b.openOnly) - Number(a.openOnly);
        return a.name.localeCompare(b.name);
    }));
    const [openDepartment, setOpenDepartment] = useState("");
    const [openDeptAmount, setOpenDeptAmount] = useState("");
    const [showDiscount, setShowDiscount] = useState(false);
    const [addingVoucher, setAddingVoucher] = useState(false);
    const [discounts, setDiscounts] = useState([]);
    const [vouchers, setVouchers] = useState([]);
    const [itemDiscounts, setItemDiscounts] = useState({});
    const [waiting, setWaiting] = useState(false);
    const [message, setMessage] = useState(false);
    const [quickLookup, setQuickLookup] = useState("");
    const [lookingUpOrder, setLookingUpOrder] = useState(false);
    const [writingNote, setWritingNote] = useState(false);
    const [returning, setReturning] = useState(false);
    const [editingCustomer, setEditingCustomer] = useState(false);
    const [makingPayment, setMakingPayment] = useState(false);
    const VATRate = order && !order.vatPayable ? 1 : DataCache.vatRate;
    const [printType, setPrintType] = useState(null);
    const [settings, setSettings] = useState([]);
    const [createCreditNote, setCreateCreditNote] = useState(false);
    const [shipping, setShipping] = useState(false);
    const [shippingMethods, setShippingMethods] = useState(false);
    const [sourceSelect, setSourceSelect] = useState(false);
    const [offerCoins, setOfferCoins] = useState(false);
    const [confirmCompletion, setConfirmCompletion] = useState(false);
    const [typedBarcode, setTypedBarcode] = useState('');
    
    // Print handling code
    const [isPrinting, setIsPrinting] = useState(false);
    const printingRef = useRef(null);

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    const scanBarcode = (text) => {
        if (text) {
            var query = {
                count: 1
            }
    
            if(text.length > 0) {
                query.TextSearch = text;
            }

            APIFetch("POST", "product", query)
            .then(result => {
                if(result.ok) {
                    const product = result.data[0];

                    if (product) {
                        APIFetch("GET", `variant/${product.id}`)
                        .then(result => {
                            if(result.ok) {
                                const variants = result.data.variants;

                                if (variants) {
                                    const match = variants.find(v => v.barcode == text);

                                    if (match) {
                                        addItem(match, product);
                                    } else {
                                        setMessage("An error occurred when loading the product data.");
                                        setWaiting(false);
                                    }
                                } else {
                                    setMessage("An error occurred when loading the product data.");
                                    setWaiting(false);
                                }
                            } else {
                                setMessage("An error occurred when loading the product data.");
                                setWaiting(false);
                            }
                        })
                        .catch(() => {
                            setMessage("An error occurred when loading the product data.");
                            setWaiting(false);
                        });
                    } else {
                        setMessage("An error occurred when loading the product data.");
                        setWaiting(false);
                    }
                } else {
                    setMessage("An error occurred when loading the product data.");
                    setWaiting(false);
                }
            })
            .catch(() => {
                setMessage("An error occurred when loading the product data.");
                setWaiting(false);
            });
        }
    }

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    useEffect(() => {
        const handleInput = (e) => {
          if (e.target.nodeName !== 'INPUT' && e.target.nodeName !== 'TEXTAREA') {
            if (e.key == 'Enter') {
                scanBarcode(typedBarcode);
                setTypedBarcode('');
            } else {
                setTypedBarcode(typedBarcode + e.key);
            }
          }
        };
    
        const handlePaste = (e) => {
          const pastedData = e.clipboardData.getData('text');
          scanBarcode(pastedData);
        };
    
        document.addEventListener('keydown', handleInput);
        window.addEventListener('paste', handlePaste);
    
        return () => {
          document.removeEventListener('keydown', handleInput);
          window.removeEventListener('paste', handlePaste);
        };
    });

    const handlePrint = useReactToPrint({
        content: () => printingRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        }
    });

    const printReceipt = (forced = false) => {
        if(!forced && (!order.transactions || order.transactions.length === 0)) {
            setMessage("Cannot print receipt as no transactions have been made.");
            return
        }

        handlePrint();
    }

    // End print handling code

    useEffect(() => {
        APIFetch("GET", 'settings')
        .then(result => {
            if(result.ok) {
                setSettings([...result.data]);
            } else {
                setMessage("An error occurred when attempting to load system settings.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when attempting to load system settings.");
        })

        // Load Shipping Methods
        APIFetch('GET', 'shippingmethod')
        .then((result) => {
            if(result.ok) {
                setShippingMethods(result.data);
            } else {
                setMessage("An error occurred when attempting to load available shipping methods.");
            }
        })
        .catch(() => { setMessage("An error occurred when attempting to load available shipping methods."); });
    }, []);

    const detectEnter = (e) => {
        if(e.key === "Enter" && !waiting) {
            setWaiting(true);
            var trimmed = quickLookup.trim().replace(/^0+/, '');

            if(trimmed.length > 0) {
                scanBarcode(trimmed);
            }
        }
    }

    const loadOrder = (newId) => {
        APIFetch("GET", `order/${newId}`)
        .then(result => {
            if(result.ok) {
                parseOrder(result.data)
            } else if(result.status === 404) {
                setMessage(`Failed to find order with reference #${newId}.`);
            } else {
                setMessage('An error occurred during order lookup.');
            }

            setWaiting(false);
        })
        .catch((e) => {
            setMessage('An error occurred during order lookup.');
            setWaiting(false);
        })
    }

    const parseOrder = (data) => {
        var newOrder = {...data};
        newOrder.items = [...newOrder.items];
        // Set Open Departments
        newOrder.openDepartments.forEach(d => {
            newOrder.items.push({
                id: d.id,
                openDepartmentId: d.id,
                barcode: '',
                price: d.price,
                vat: d.vat,
                status: d.status,
                description: "Open Department - " + (DataCache.departmentLookup[d.departmentId] ? DataCache.departmentLookup[d.departmentId].name : 'Unknown'),
                productCode: ""
            });
        });
        setOrder(newOrder);
        
        // Set Discounts and Item Discounts
        var newDiscounts = [];
        var newItemDiscounts = {};

        newOrder.discounts.forEach(d => {
            var newItem = {
                id: d.id,
                type: d.flatDiscount > 0 ? 'fixed' : 'percentage',
                amount: d.flatDiscount || d.percentageDiscount,
                currentValue: d.currentValue,
                discount: d.discount
            };

            if(d.orderItemId && d.orderItemId > 0) {
                if(newItemDiscounts[d.orderItemId]) {
                    newItemDiscounts[d.orderItemId].push(newItem);
                } else {
                    newItemDiscounts[d.orderItemId] = [newItem];
                }
            } else {
                newDiscounts.push(newItem)
            }
        });

        setDiscounts(newDiscounts);
        setItemDiscounts(newItemDiscounts);

        // Set Vouchers
        setVouchers(newOrder.vouchers.map(v => {
            return {
                id: v.id,
                amount: v.initialValue,
                code: v.code,
                currentValue: v.currentValue,
                void: v.void,
                expires: v.expires
            }
        }))

        setQuickLookup("");
        setLookingUpOrder(false);
    }

    useEffect(() => {
        var tillIdOverride = null;
        if(location.state && location.state.till) {
            setTill(location.state.till);
            tillIdOverride = location.state.till.id;
        }
        
        if((tillIdOverride || tillId) >= 0) {
            APIFetch('GET', `till/${tillIdOverride || tillId}`)
            .then(result => {
                if(result.ok) {
                    setTill(result.data);
                    if(orderId >= 0) {
                        loadOrder(orderId);
                    }
                } else {
                    setMessage('An error occurred when attempting to load the till.');
                }
            })
            .catch(e => {  setMessage('An error occurred when attempting to load the till.'); });
        }
    }, [tillId, orderId]);

    const resetOrder = () => {
        setOrder(null);
        setDiscounts([]);
        setItemDiscounts([]);
        setVouchers([]);
        navigate(`/till/${till.id}`, { state: { backUrl: backUrl } });
    }

    const createOrder = () => {
        return APIFetch("POST", 'order', {
            VATPayable: true,
            currency: 'GBP',
            subTotal: 0,
            VAT: 0,
            total: 0,
            type: 'EPOS',
            status: "",
            tillId: till.id,
            userId: currentUser ? currentUser.id : AuthStore.currentState.userId,
            items: [],
            discounts: [],
            openDepartments: []
        });
    }

    const addItem = (variant, product) => {
        if(waiting) return;
        setWaiting(true);
        if(order) {
            APIFetch("POST", `order/${order.id}/item`, {
                basePrice: variant.price || product.price || 0,
                VAT: Math.round(((variant.price || product.price || 0) * (VATRate - 1)) * 100) / 100,
                status: "",
                variantId: variant.id,
                tillId: till.id
            })
            .then(result => {
                if(result.ok) {
                    APIFetch('POST', `order/${order.id}/vat/${order.vatPayable}`)
                    .then(result2 => {
                        if (result2.ok) {
                            parseOrder(result2.data);
                        } else {
                            parseOrder(result.data);
                        }
                    });

                    setSelectingVariant(null);
                } else {
                    setMessage("An error occurred while attempting to add an item to the order.");
                }

                setWaiting(false);
            })
            .catch(e => {
                setMessage("An error occurred while attempting to add an item to the order.");
                setWaiting(false);
            });
        } else {
            // Create Order
            createOrder()
            .then(result => {
                if(result.ok) {
                    var orderData = result.data;

                    APIFetch("POST", `order/${orderData.id}/item`, {
                        basePrice: variant.price || product.price || 0,
                        VAT: Math.round(((variant.price || product.price || 0) * (VATRate - 1)) * 100) / 100,
                        status: "",
                        variantId: variant.id,
                        tillId: till.id
                    })
                    .then(result => {
                        if(result.ok) {
                            APIFetch('POST', `order/${orderData.id}/vat/${orderData.vatPayable}`)
                            .then(result2 => {
                                if (result2.ok) {
                                    parseOrder(result2.data);
                                } else {
                                    parseOrder(result.data);
                                }
                            });

                            setSelectingVariant(null);
                        } else {
                            setMessage("An error occurred while attempting to add an item to the order.");
                        }

                        setWaiting(false);
                    })
                    .catch(e => {
                        setMessage("An error occurred while attempting to add an item to the order.");
                        setOrder(orderData);
                        setWaiting(false);
                    });
                } else {
                    setMessage("An error occurred while attempting to create an order.");
                    setWaiting(false);
                }
            })
            .catch(e => {
                setMessage("An error occurred while attempting to create an order.");
                setWaiting(false);
            })
        }
    }

    const addOpenDepartment = () => {
        if(!waiting && openDepartment && openDeptAmount && openDeptAmount.length > 0 && !isNaN(openDepartment)) {
            setWaiting(true);
            var openDeptName = departments.find(d => d.id.toString() === openDepartment).name;
            var basePrice = openDeptAmount / VATRate;
            var vat = (VATRate - 1) * openDeptAmount;

            if(order === null) {
                createOrder()
                .then(result => {
                    if(result.ok) {
                        var orderData = result.data;

                        APIFetch('POST', `order/${orderData.id}/dept`, {
                            BasePrice: basePrice,  
                            VAT: vat,
                            Status: "",
                            DepartmentId: openDepartment
                        })
                        .then(result => {
                            if(result.ok) {
                                parseOrder(result.data);
                                setOpenDepartment("");
                                setOpenDeptAmount("");
                                setWaiting(false);
                            } else {
                                setMessage("An error occurred when adding the open department cost.");
                                setWaiting(false);
                            }
                        })
                        .catch(e => {
                            setMessage("An error occurred when adding the open department cost.");
                            setWaiting(false);
                        });
                    } else {
                        setMessage("An error occurred while attempting to create an order.");
                        setWaiting(false);
                    }
                })
                .catch(e => {
                    setMessage("An error occurred while attempting to create an order.");
                    setWaiting(false);
                })
            } else {
                APIFetch('POST', `order/${order.id}/dept`, {
                    BasePrice: basePrice,
                    VAT: vat,
                    Status: "",
                    DepartmentId: openDepartment
                })
                .then(result => {
                    if(result.ok) {
                        parseOrder(result.data);
                        setOpenDepartment("");
                        setOpenDeptAmount("");
                        setWaiting(false);
                    } else {
                        setMessage("An error occurred when adding the open department cost.");
                        setWaiting(false);
                    }
                })
                .catch(e => {
                    setMessage("An error occurred when adding the open department cost.");
                    setWaiting(false);
                });
            }
        }
    }

    const addDiscount = (discount) => {
        var itemSpecific = showDiscount !== true && showDiscount !== false;

        APIFetch("POST", `order/${order.id}/discount`, {
            coupon: discount.coupon,
            flatDiscount: discount.type === 'fixed' ? parseFloat(discount.amount) : 0,
            percentageDiscount: discount.type === 'percentage' ? parseFloat(discount.amount) : 0,
            orderId: order.id,
            orderItemId: itemSpecific ? showDiscount : null
        })
        .then(result => {
            if(result.ok) {
                parseOrder(result.data);
                setShowDiscount(false);
            } else if(discount.coupon && result.status === 400) {
                setMessage(result.data || "An error occurred when attempting to apply the discount.");
            } else if(discount.coupon && result.status === 404) {
                setMessage("The specified coupon could not be found or is no longer valid.");
            } else if(result.status === 409) {
                setMessage("The specified discount has already been applied to this order.");
            } else {
                setMessage("An error occurred when attempting to apply the discount.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when attempting to apply the discount.");
        });
    }

    const addVoucher = (amount) => {
        if(!waiting) {
            setWaiting(true);
            if(order) {
                APIFetch("POST", `order/${order.id}/voucher/${amount}`)
                .then(result => {
                    if(result.ok) {
                        parseOrder(result.data);
                        setAddingVoucher(false);
                    } else {
                        setMessage("An error occurred when attempting to create the voucher.");
                    }
                })
                .catch(e => {
                    setMessage("An error occurred when attempting to create the voucher.");
                })
                .finally(() => {
                    setWaiting(false);
                })
            } else {
                createOrder()
                .then(result => {
                    if(result.ok) {
                        var orderData = result.data;
                        var success = false;

                        APIFetch("POST", `order/${orderData.id}/voucher/${amount}`)
                        .then(result => {
                            if(result.ok) {
                                parseOrder(result.data);
                                success = true;
                                setAddingVoucher(false);
                            } else {
                                setMessage("An error occurred when attempting to create the voucher.");
                            }
                        })
                        .catch(e => {
                            setMessage("An error occurred when attempting to create the voucher.");
                        })
                        .finally(() => {
                            if(!success) setOrder(orderData);
                            setWaiting(false);
                        });
                    } else {
                        setMessage("An error occurred while attempting to create an order.");
                        setWaiting(false);
                    }
                })
                .catch((e) => {
                    setMessage("An error occurred while attempting to create an order.");
                    setWaiting(false);
                });
            }
        }
    }

    const updateCustomer = (c) => {
        var offerCoins = !order.customer && (order.status == "Fully Paid" || order.status == "Processing" || order.status == "Completed");
        var newOrder = {...order};
        newOrder.customer = c;
        setOrder(newOrder);
        setEditingCustomer(false);
        if(offerCoins) setOfferCoins(order);
    }

    const addNote = (note) => {
        var newOrder = {...order};
        newOrder.notes = [...newOrder.notes, note].sort((a, b) => {
            return b.createdAt.localeCompare(a.createdAt)
        });
        setOrder(newOrder);
        setWritingNote(false);
    }

    const addTransaction = (t, priorBalance) => {
        var newOrder = {...order};
        newOrder.transactions = [...(newOrder.transactions || []), ...t].sort((a, b) => {
            return a.createdAt.localeCompare(b.createdAt)
        });

        setTill({ ...till, balance: t[0].newTillBalance });

        setMakingPayment(false);

        var newBalance = priorBalance;

        t.forEach(a => newBalance -= a.amount);

        if((order.status === 'New' || order.status === 'Deposit Paid') && t[0].amount > 0 && newBalance === 0) {
            updateOrderStatus('Fully Paid', newBalance, newOrder);
        } else if(order.status === 'New' && t[0].amount > 0) {
            updateOrderStatus('Deposit Paid', newBalance, newOrder);
        } else {
            setOrder(newOrder);
        }
    }

    const updateAddressField = (type, address) => {
        var newOrder = {...order};

        if(type === 'delivery') {
            newOrder.deliveryAddressId = address ? address.id : null;
        } else {
            newOrder.billingAddressId = address ? address.id : null;
        }

        if(address) {
            if(!newOrder.customer.addresses) {
                newOrder.customer.addresses = [address];
            } else if(!newOrder.customer.addresses.find(a => a.id === address.id)){
                newOrder.customer.addresses = [...newOrder.customer.addresses, address];
            }
        }

        setOrder(newOrder);
    }

    const deleteAddress = (address) => {
        var newOrder = {...order};
        newOrder.customer.addresses = [...newOrder.customer.addresses.filter(a => a.id !== address.id), {...address, active: false }];
        setOrder(newOrder);
    }

    const updateOrderStatus = (status, currentBalance, orderOverride, customerSource = -1) => {
        var currentOrder = orderOverride || order;
        if(status === 'Deposit Paid' && (!currentOrder.transactions || currentOrder.transactions.length === 0)) {
            setMessage("A deposit hasn't been paid yet.");
            return;
        } else if(status === 'Fully Paid' && currentBalance > 0) {
            setMessage("The order can't be marked fully paid with an outstanding balance.");
            return;
        } else if(status === 'Completed' && currentBalance > 0) {
            setMessage("The order can't be marked completed with an outstanding balance.");
            return;
        }
        
        if((status === 'Fully Paid' || (status === 'Completed' && order.status !== 'Fully Paid' && order.status !== 'Processing')) && customerSource === -1) {
            setSourceSelect({ status, currentBalance, orderOverride });
            return;
        } else {
            setSourceSelect(null);
        }
        
        APIFetch('POST', `order/${currentOrder.id}/status`, { status, currentLocation: till.locationId, customerSource })
        .then(result => {
            if(result.ok) {
                var newOrder = {...currentOrder};
                newOrder.status = status;
                setOrder(newOrder);
                loadOrder(newOrder.id);
                if(status === "Fully Paid") {
                    setConfirmCompletion(true);
                }
            } else if(result.status === 400) {
                setMessage(result.data || 'An error occurred when attempting to change the order status.');
            } else if(result.status === 404) {
                setMessage("One or more items are out of stock.");
            } else {
                setMessage('An error occurred when attempting to change the order status.');
            }
        })
        .catch(e => {
            setMessage('An error occurred when attempting to change the order status.');
        });
    }

    const setItemFrom = (item, locId) => {
        var newOrder = {...order};
        var newItems = [...newOrder.items];
        var matchIdx = newItems.findIndex(i => i.id === item.id);
        newItems[matchIdx] = {...newItems[matchIdx], stockFrom: locId};
        newOrder.items = newItems;
        setOrder(newOrder);
    }

    var totalPaid = order && order.transactions ? order.transactions.reduce((acc, o) => { return acc + parseFloat(o.amount) }, 0)  : null;
    var balance = order ? (order.total - totalPaid) : 0;
    if(balance < 0.01 && balance > -0.01) balance = 0;

    var printObject = null;

    if(isPrinting) {
        switch (printType) {
            case "OPEN_TILL":
                printObject = <div ref={printingRef}></div>
                break;
            case "3":
                var address = order.deliveryAddressId || order.billingAddressId ? order.customer.addresses.find(a => a.id === (order.deliveryAddressId || order.billingAddressId)) : null;
                if(address !== null) {
                    printObject = <PostLabel ref={printingRef} addressLines={[address.name, address.address1, address.address2, address.city, address.postcode, address.country]} />;
                } else {
                    printObject = <PostLabel ref={printingRef} addressLines={["No customer address provided."]} />;
                }
                break;
            case "4":
                let addressLines = [
                    "From",
                    get(settings.find(s => s.name === "business-name"), "value") || "",
                    get(settings.find(s => s.name === "address1"), "value") || "",
                    get(settings.find(s => s.name === "address2"), "value") || "",
                    get(settings.find(s => s.name === "town"), "value") || "",
                    get(settings.find(s => s.name === "county"), "value") || "",
                    get(settings.find(s => s.name === "postcode"), "value") || ""
                ].filter(l => l && l.length);
                printObject = <PostLabel ref={printingRef} addressLines={addressLines} />;
                break;
            case "5":
            case "6":
                printObject = <PackingSlipPrint 
                    ref={printingRef} 
                    order={order}
                    discounts={discounts}
                    itemDiscounts={itemDiscounts}
                    vouchers={vouchers}
                    VATRate={VATRate}
                    settings={settings}
                    showTransactions={printType === "6"}/>;
                break;
            default:
            case "1":
            case "2":
                // Receipt / Refund Receipt
                printObject = <ReceiptPrint 
                    ref={printingRef} 
                    order={order}
                    discounts={discounts}
                    itemDiscounts={itemDiscounts}
                    vouchers={vouchers}
                    VATRate={VATRate}
                    tillUserName={currentUser ? currentUser.name : AuthStore.currentState.name}
                    settings={settings}
                    isRefund={printType === "2"}
                />
                break;
        }
    }

    return <div className="flex flex-col md:flex-row p-2 h-full overflow-x-hidden">
        {printObject}
        { selectingProduct ? <Modal onClose={() => { setSelectingProduct(false) }}><ProductManagement isModal={true} onSelected={(product) => { setSelectingVariant(product); setSelectingProduct(false); }}/></Modal> : null }
        { selectingVariant ? <TillVariantSelector onClose={() => { setSelectingVariant(null) }} product={selectingVariant} location={{ id: till.locationId, name: till.locationName }} onSuccess={addItem}/> : null }
        {adjusting ? <TillAdjustmentModal till={till} onClose={() => { setAdjusting(false) }} onSuccess={(adjustment, comment, newBalance) => { 
            let newTill = {...till, balance: parseFloat(till.balance || 0) + adjustment };
            setAdjusting(false); 
            setPrintingAdjustment({ date: new Date(), till: till.name, user: currentUser ? currentUser.name : AuthStore.currentState.name, amount: adjustment, comment: comment, newBalance }); 
            setTill(newTill); 
        }}/> : null }
        {printingAdjustment ? <TillAdjustmentPrintModal onClose={() => { setPrintingAdjustment(null) }} adjustment={printingAdjustment} /> : null}
        {switching ? <TillUserModal onClose={() => { setSwitching(false) }} onSuccess={(userDetails) => { setSwitching(false); setCurrentUser(userDetails); }}/> : null }
        {showDiscount ? <TillDiscountModal isItem={showDiscount !== true && showDiscount !== false} onClose={() => { setShowDiscount(false) }} onSuccess={(discount) => { addDiscount(discount) }}/> : null }
        {addingVoucher ? <TillVoucherModal onClose={() => { setAddingVoucher(false) }} onSuccess={(amount) => { addVoucher(amount) }}/> : null }
        {lookingUpOrder ? <TillOrderLookupModal onClose={() => { setLookingUpOrder(false) }} onSuccess={(orderId) => { navigate(`/till/${till.id}/${orderId}`, { state: { backUrl: backUrl } }); }}/> : null }
        {writingNote ? <TillNoteModal orderId={order.id} onClose={() => { setWritingNote(false) }} onSuccess={addNote}/> : null }
        {shipping ? <TillShipmentModal order={order} shippingMethods={shippingMethods} userId={currentUser ? currentUser.id : AuthStore.currentState.userId} onClose={() => { setShipping(false) }} onSuccess={() => { setShipping(false); loadOrder(order.id); }}/> : null }
        {returning ? <TillReturnModal order={order} discounts={discounts} itemDiscounts={itemDiscounts} tillId={till.id} userId={currentUser ? currentUser.id : AuthStore.currentState.userId} onClose={() => { setReturning(false) }} onSuccess={(value) => { 
            setReturning(false);
            loadOrder(order.id);
            setTill({ ...till, balance: till.balance - (value || 0) }); }}/>
        : null }
        {editingCustomer ? <TillCustomerModal customer={order.customer} orderId={order.id} onClose={() => setEditingCustomer(false)} onSuccess={(c) => { updateCustomer(c) }} /> : null }
        {makingPayment ? <TillPaymentModal onClose={() => { setMakingPayment(false) }} onSuccess={(t) => addTransaction(t, balance)} max={balance} customer={order ? order.customer : null} baseTransaction={{
            orderId: order.id,
            tillId: till.id,
            userId: currentUser ? currentUser.id : AuthStore.currentState.userId
        }}/> : null}
        {sourceSelect ? <TillCustomerSourceModal 
            onSuccess={(sourceId) => { updateOrderStatus(sourceSelect.status, sourceSelect.currentBalance, sourceSelect.orderOverride, sourceId) }} 
            onClose={() => { updateOrderStatus(sourceSelect.status, sourceSelect.currentBalance, sourceSelect.orderOverride, null) }}
            onCancel={() => setSourceSelect(null)}/> : null}
        {createCreditNote ? <TillCreditNoteModal onClose={() => setCreateCreditNote(false)} onSuccess={() => setCreateCreditNote(false)} settings={settings}/> : null}
        {offerCoins ? <TillRetroactivePointsModal order={offerCoins} onClose={() => {setOfferCoins(null)}}/> : null}
        {confirmCompletion ? <TillConfirmCompletionModal onSuccess={() => { updateOrderStatus('Completed', 0); setConfirmCompletion(false); }} onClose={() => { setConfirmCompletion(false) }}/> : null}
        <MessageModal message={message} onClose={() => setMessage(null)} />
        <div className='min-h-[500px] md:min-h-[0px] flex flex-col p-5 pr-10 overflow-y-auto border-b md:border-b-0 md:border-r border-b-brand-grey md:border-r-brand-grey md:my-6 md:mr-4'>
          <img src={textLogo} width={175} className="ml-auto mr-auto"/>
          <input type="text" placeholder="Quick lookup..." className="mt-10 mb-4 mr-12 ml-2" onKeyUp={detectEnter} value={quickLookup} onChange={(e) => { setQuickLookup(e.target.value); }}/>
          <div className="mt-4">
            <div className="font-medium p-2 pb-1">{till.name}: {currentUser ? currentUser.name : AuthStore.currentState.name}</div>
            <div className="p-2 text-sm pt-0">Till Balance: £{(till.balance || 0).toFixed(2)}</div>
          </div>
          {!order || order.status !== 'Completed' ? <div className="flex flex-col mt-4 max-w-[175px]">
            <div className="font-medium p-2 pb-1">Open Department</div>
            <select className="m-2 mt-2" value={openDepartment} onChange={(e) => { setOpenDepartment(e.target.value) }}>
                <option value='' hidden>Select department...</option>
                {departments && departments.length > 0 ? departments.map(o => { return <option key={o.id} value={o.id.toString()}>{o.name}{o.openOnly ? "*" : ""}</option> }): null}
            </select>
            <input type="number" value={openDeptAmount} placeholder="Amount..." className="m-2 px-1 text-sm mt-2" onChange={(e) => setOpenDeptAmount(e.target.value)}/>
            <div className={"flex btn btn-sm ml-auto mr-auto mt-2"} style={{ minWidth: 100 }}  onClick={() => { addOpenDepartment() }}>Add</div>
          </div> : null }
          {order && order.id ? <div className="mt-4">
            <div className="font-medium p-2 pb-1">Order {`#${order.id}`}</div>
            <div className="p-2 text-sm">
                <div className="flex flex-row">
                    <div className="mr-2">
                        Date:
                    </div>
                    <div>
                        {order.createdAt ? format(new Date(order.createdAt), "eee, MMM do HH:mmaaa") : "" }
                    </div>
                </div>
                <div className="flex flex-row mt-1">
                    <div className="mr-2 text-sm">
                        Type:
                    </div>
                    <div>
                        {order.type ? order.type : "" }
                    </div>
                </div>
                <div className="flex flex-row items-center mt-1">
                    <div className="mr-2 text-sm">
                        Status:
                    </div>
                    <select className={"text-sm"} value={order.status || "New"}  onChange={(e) => { updateOrderStatus(e.target.value, balance); }}>
                        <option value={"New"}>New</option>
                        <option value={"Deposit Paid"}>Deposit Paid</option>
                        <option value={"Awaiting Stock"}>Awaiting Stock</option>
                        <option value={"Fully Paid"}>Fully Paid</option>
                        <option value={"Processing"}>Processing</option>
                        <option value={"Completed"}>Completed</option>
                    </select>
                </div>
                <div className="flex flex-row mt-1">
                    <div className="mr-2 text-sm">
                        Total:
                    </div>
                    <div>
                        {order.total ? `£${order.total}` : "" }
                    </div>
                </div>
            </div>
          </div> : null }
          {order && order.id ? <div className="flex flex-col mt-4">
            <div className="font-medium p-2 pb-1">Customer Details</div>
            {order.customer ? <div className="p-2 text-sm">
                <div className="">{order.customer.firstName} {order.customer.surname || ""}</div>
                <div className="flex flex-row">
                    <div className="mr-2">Email:</div>
                    <div>{order.customer.email}</div>
                </div>
                <div className="flex flex-row">
                    <div className="mr-2">Tel:</div>
                    <div>{order.customer.phoneNumber}</div>
                </div>
                <div className="flex flex-row">
                    <div className="mr-2">Loyalty Points:</div>
                    <div>{order.customer.pointBalance} (£{(order.customer.pointBalance * ((parseInt(get(settings.find(s => s.name === "point-value"), "value") || 1) / 100) || "0.00")).toFixed(2)})</div>
                </div>
                <div className="btn btn-sm mt-3 mr-auto" onClick={() => { setEditingCustomer(true) }}>Update Customer</div>
            </div>
             : <div className="btn btn-sm mt-3" onClick={() => { setEditingCustomer(true) }}>Set Customer</div>}
            </div> : null }
            <TillAddressBlock order={order} updateAddressField={updateAddressField} onDeleteAddress={deleteAddress}/>
            {order && order.id ?  <div className="flex flex-col mt-4">
                <div className="font-medium p-2 pb-1">Printing</div>
                <div className="flex flex-row items-center">
                    <select className="text-sm mr-4" value={printType || "1"}  onChange={(e) => { setPrintType(e.target.value); }}>
                        <option value={"1"}>Receipt</option>
                        <option value={"2"}>Refund Receipt</option>
                        <option value={"3"}>Label</option>
                        <option value={"4"}>Return Label</option>
                        <option value={"5"}>Packing Slip</option>
                        <option value={"6"}>Packing Slip /w Transactions</option>
                    </select>
                    <div className="btn btn-sm flex-1" onClick={() => { printReceipt() }}>Print</div>
                </div>
            </div> : null }
        </div>
        <div className="min-h-[500px] md:min-h-[0px] flex flex-col flex-1 p-5 mr-4 overflow-x-auto">
            <div className="flex flex-row ml-auto items-center mb-6 overflow-x-auto">
                <div className={"pr-2 btn btn-bar ml-auto"} style={{ minWidth: 75 }}  onClick={() => { setLookingUpOrder(true) }}>Lookup Order</div>
                <div className={"px-2 btn btn-bar"} style={{ minWidth: 75 }}  onClick={() => { resetOrder() }}>New Order</div>
                <div className={"px-2 btn btn-bar"} style={{ minWidth: 75 }} onClick={() => { setCreateCreditNote(true) }}>Create Credit Note</div>
                <div className={"px-2 btn btn-bar"} style={{ minWidth: 75 }} onClick={() => { setAdjusting(true) }}>Adjust Till</div>
                <div className={"px-2 btn btn-bar"} style={{ minWidth: 75 }} onClick={() => { setPrintType("OPEN_TILL"); printReceipt(true); }}>Open Till</div>
                <div className={"px-2 btn btn-bar"} style={{ minWidth: 75 }} onClick={() => { setSwitching(true) }}>Switch User</div>
                <div className={"btn btn-bar"} style={{ minWidth: 75 }}  onClick={() => { backUrl ? navigate(backUrl) : navigate(-1); }}>Exit</div>
            </div>
            <div className="flex flex-row flex-1 bg-brand-grey-light overflow-y-auto p-8 relative">
                { waiting ? <OverlaySpinner /> : null }
                {!order && (!vouchers || vouchers.length == 0) ? <div className="text-2xl font-medium m-auto">Empty Order</div> : 
                    <OrderDetails 
                        order={order}
                        discounts={discounts}
                        itemDiscounts={itemDiscounts}
                        vouchers={vouchers}
                        settings={settings}
                        till={till}
                        setOrder={setOrder}
                        parseOrder={parseOrder}
                        setShowDiscount={setShowDiscount}
                        setItemFrom={setItemFrom}
                    />
                }
            </div>
            <div className="flex flex-row ml-auto items-center mt-6">
                {(order?.items?.length > 0 || vouchers?.length > 0) && order.status !== 'Completed' ? <div className={"flex px-2 btn btn-bar ml-auto"} style={{ minWidth: 75 }}  onClick={() => { setMakingPayment(true) }}>Add Payment</div> : null }
                {!order || order.status !== 'Completed' ? <div className={"flex px-2  btn btn-bar ml-auto"} style={{ minWidth: 75 }}  onClick={() => { setSelectingProduct(true) }}>Add Item</div> : null }
                {(order?.items?.length > 0 || vouchers?.length > 0) && order.status !== 'Completed' ? <div className={"flex btn btn-bar px-2"} style={{ minWidth: 75 }} onClick={() => { setShowDiscount(true) }}>Add Discount</div> : null}
                {order?.status === 'Deposit Paid' || order?.status === 'Fully Paid' || order?.status === 'Processing' || order?.status === 'Completed' ? <div className={"flex btn btn-bar px-2"} style={{ minWidth: 75 }} onClick={() => { setShipping(true) }}>Add Shipment</div> : null}
                {order?.status === 'Completed' ? <div className={"flex btn btn-bar px-2"} style={{ minWidth: 75 }} onClick={() => { setReturning(true) }}>Refund / Return</div> : null}
                {(order?.items?.length > 0 || vouchers?.length > 0) ? <div className={"flex btn btn-bar px-2"} style={{ minWidth: 75 }} onClick={() => { setWritingNote(true) }}>Add Note</div> : null}
                {!order || order.status !== 'Completed' ? <div className={"flex btn btn-bar"} style={{ minWidth: 75 }} onClick={() => { setAddingVoucher(true) }}>Buy Voucher</div> : null }
            </div>
        </div>
    </div>
}

export default Till;