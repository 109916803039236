import { forwardRef } from "react";
import Barcode from "react-barcode";
import logo from '../../assets/images/chatleys.png';
import OrderDetails from "../../pages/Till/OrderDetails";
import appConfig from "../../appConfig.json";

const get = (obj, property) => {
    if(!obj) return null;
    return obj[property];
}

function PackingSlipPrint({ order, discounts, itemDiscounts, vouchers, VATRate, settings, showTransactions = false, multiPrint = false }, ref) {
    var deliveryAddress = order.deliveryAddressId ? order.customer.addresses.find(a => a.id === (order.deliveryAddressId)) : null;
    var deliveryLines = deliveryAddress ? [deliveryAddress.name, deliveryAddress.address1, deliveryAddress.address2, deliveryAddress.city, deliveryAddress.postcode, deliveryAddress.country] : null;

    var billingAddress = order.billingAddressId ? order.customer.addresses.find(a => a.id === (order.billingAddressId)) : null;
    var billingLines = billingAddress ? [billingAddress.name, billingAddress.address1, billingAddress.address2, billingAddress.city, billingAddress.postcode, billingAddress.country] : null;

    return <div ref={ref} className={multiPrint ? "onlyPrint w-[100vw] m-h-[100vh] m-0 p-3 py-4 inline-block" : "onlyPrint absolute top-0 left-0 w-full max-w-full m-0 p-4 text-sm"}>
        <div className="flex flex-col">
            <object data={logo} type="image/png" className="flex mx-auto h-[50px] min-w-0 mb-8">
                <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].ASSETS_DOMAIN + `system/logo.jpg?${Math.round(Date.now() / 1000)}` || logo} width={400} className="mb-8"/>
            </object>
            <div className="flex flex-row">
                <div className="flex flex-col basis-1/2 border border-black">
                    <div className="flex flex-row border-black border-b">
                        <div className="flex flex-col flex-1 p-2 border-black border-r leading-4">
                            { deliveryLines || billingLines  ? (deliveryLines || billingLines).map((l, i) => {
                                return <div className={i === 0 ? "font-bold" : ""}>{l}</div>
                            }) : null}
                        </div>
                        <div className="flex flex-col p-2 pr-4">
                            <div>Order Number:</div>
                            <div>{order.id}</div>
                        </div>
                    </div>
                    <div className="flex flex-row border-black border-b p-2">
                        <div className="mb-4">Returned Item(s)</div>
                    </div>
                    <div className="flex flex-row border-black border-b p-2">
                        <div className="mb-4">Reason For Return</div>
                    </div>
                    <div className="flex flex-row p-2">
                        <div className="mb-8">Preferred Course Of Action</div>
                    </div>
                </div>
                <div className="flex flex-col basis-1/2 border border-black p-2 text-lg leading-5">
                    <div className="font-bold mb-4">Return To:</div>
                    {[get(settings.find(s => s.name === "business-name"), "value") || "",
                    get(settings.find(s => s.name === "address1"), "value") || "",
                    get(settings.find(s => s.name === "address2"), "value") || "",
                    get(settings.find(s => s.name === "town"), "value") || "",
                    get(settings.find(s => s.name === "county"), "value") || "",
                    get(settings.find(s => s.name === "postcode"), "value") || ""].filter(l => l && l.length).map(l => {
                        return <div className="font-bold">{l}</div>
                    })}
                    <div className="mb-4 mt-4 font-bold">
                        Tel: {settings.filter(s => s.name.startsWith("phone_number_")).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(p => p.value).find(p => true)}
                    </div>
                    <Barcode 
                        value={order.id.toString()}
                        displayValue={false}
                        height={40}
                        width={1.6}
                    />
                </div>
            </div>
            <div className="flex flex-row p-2">
                <div className="flex basis-1/2 mr-2">Note: If you require replacement items to be sent due to the items being too small or large under the Preferred Course Of Action box: Please write Style colour and size you would like to exchange. All exchanges have to be of the same value if not you will need to be refunded for the item and will have to re-order the goods. Or if you wish you can contact us on 0161 764 3331 for assistance we will be able to help during working hour.</div>
                <div className="flex basis-1/2 ml-2">Please detach and use the above if you need to return any items purchased</div>
            </div>
            <div className="flex flex-1 w-100 border-dotted border-t border-t-black mt-2 mb-3"></div>
            <div className="flex flex-row">
                <div className="flex flex-col basis-1/2">
                    <div className="font-bold mb-1">Invoiced To:</div>
                    { billingLines || deliveryLines  ? (billingLines || deliveryLines).map((l, i) => {
                        return <div className={i === 0 ? "font-bold leading-5" : "leading-4"}>{l}</div>
                    }) : null}
                </div>
                <div className="flex flex-col basis-1/2">
                    <div className="font-bold mb-1">Delivered To:</div>
                    { deliveryLines || billingLines  ? (deliveryLines || billingLines).map((l, i) => {
                        return <div className={i === 0 ? "font-bold leading-5" : " leading-4"}>{l}</div>
                    }) : null}
                </div>
            </div>
            <div className="flex flex-row mt-5 mb-4">
                <div className="font-bold mr-4">Order Number:</div>
                <div className="mr-8">{"#" + order.id}</div>
                <div className="font-bold mr-2">Date:</div>
                <div className="mr-8">{(new Date()).toLocaleDateString()}</div>
                <div className="font-bold mr-2">Type:</div>
                <div className="">{order.type}</div>
            </div>
            <OrderDetails
                itemsOnly={true}
                isPrint={true}
                isRefund={false}
                order={order}
                discounts={discounts}
                itemDiscounts={itemDiscounts}
                vouchers={vouchers}
                VATRate={VATRate}
                settings={settings}
            />
            {showTransactions ? <OrderDetails
                transactionsOnly={true}
                isPrint={true}
                isRefund={false}
                order={order}
                discounts={discounts}
                itemDiscounts={itemDiscounts}
                vouchers={vouchers}
                VATRate={VATRate}
                settings={settings}
            /> : null}
            <div className="mt-0">
                <Barcode value={order.id.toString()} height={40} fontSize={10}/>
            </div>
            <div  className="flex flex-row mt-0">
                <div className="">VAT Registration No. <strong>146376649</strong></div>
            </div>
        </div>
    </div>;
}

export default forwardRef(PackingSlipPrint);