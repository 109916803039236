import { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MessageModal from "../../components/MessageModal";
import APIFetch from "../../utilities/APIFetch";
import Modal from "../../components/Modal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { faChevronLeft, faEdit, faFileImport, faPlus, faPrint, faRightLeft, faSave, faTimes, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import LookupInput from "../../components/LookupInput";
import TemplateImportModal from "./TemplateImportModal";
import DataCache from "../../utilities/DataCache";
import createItem from "../../utilities/ItemCreation";
import MissingVariantModal from "./MissingVariantModal";
import StockGrid from "../../components/StockGrid";
import { usePrompt } from "../../utilities/Blocker";
import OverlaySpinner from "../../components/OverlaySpinner";
import MediaSection from "./MediaSection";
import RichTextEditor from "react-rte";
import AddTagModal from "./AddTagModal";
import PendingAssignmentsModal from "./PendingAssignmentsModal";
import LabelPrintingModal from "./LabelPrintingModal";
import { useReactToPrint } from "react-to-print";
import LabelPrint from "../../components/PrintTemplates/LabelPrint";
import LabelConfigModal from "./LabelConfigModal";
import sortSizes from "../../utilities/SizeSort";

function ProductDetails({ productId, onBack }) {
    const VATRate = DataCache.vatRate;
    let params = useParams();
    let location = useLocation();
    let id = productId ? productId : (params ? params.id : null);
    let product = location ? location.product : null;
    let navigate = useNavigate();

    const [processing, setProcessing] = useState(false);
    const [products, setProducts] = useState(null);
    const [message, setMessage] = useState(null);
    const [confirmSave, setConfirmSave] = useState(null);
    const [pendingDelete, setPendingDelete] = useState(false);
    const [pendingVariationDelete, setPendingVariationDelete] = useState(false);
    const [pendingVariantDelete, setPendingVariantDelete] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(product ? {...product, basePriceIncVAT: product.basePrice * VATRate, basePriceIncVATOverride: null, basePriceOverride: null } : null);
    const [variants, setVariants] = useState([]);
    const [variations, setVariations] = useState([]);
    const [variationAdd, setVariationAdd] = useState(false);
    const [newVariationName, setNewVariationName] = useState('');
    const [productDescription, setProductDescription] = useState(RichTextEditor.createEmptyValue());
    const [productMatComp, setProductMatComp] = useState(RichTextEditor.createEmptyValue());
    const [loading, setLoading] = useState(true);
    const [tags, setTags] = useState([]);
    const [addingTag, setAddingTag] = useState(false);
    const [removedVariantFieldValues, setRemovedVariantFieldValues] = useState([]);
    const [pendingAssignments, setPendingAssignments] = useState(null);
    const [newStockLabels, setNewStockLabels] = useState(null);
    const [printLabels, setPrintLabels] = useState(null);
    const [printTypeModal, setPrintTypeModal] = useState(null);

    const [importModal, setImportModal] = useState(false);

    // Multi edit
    const [costOverride, setCostOverride] = useState("");
    const [priceOverride, setPriceOverride] = useState("");
    const [replenishmentOverride, setReplenishmentOverride] = useState("");

    // New variation management
    const [variationConfig, setVariationConfig] = useState(null);
    const [editingVariation, setEditingVariation] = useState(null);
    const [variationEditConfirmation, setVariationEditConfirmation] = useState(null);
    const [missingModal, setMissingModal] = useState(false);

    const [pendingProductChanges, setPendingProductChanges] = useState(false);
    const [pendingVariantChanges, setPendingVariantChanges] = useState(false);

    // Stock management
    const [locations, setLocations] = useState([])

    // Row selection
    const [selectedVariants, setSelectedVariants] = useState([]);

    const [labelConfigModal, setLabelConfigModal] = useState(false);
    const [labelSkip, setLabelSkip] = useState(0);

    const mediaRef = useRef();
    
    // Print handling code
    const [isPrinting, setIsPrinting] = useState(false);
    const printingRef = useRef(null);

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => printingRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
            setLabelSkip(0);
            setPrintLabels(null); 
        }
    });

    // Block nav prompts
    usePrompt("One or more variants haven't been saved and will be lost. Are you sure you wish to leave?", pendingVariantChanges);
    usePrompt("One or more product details haven't been saved and will be lost. Are you sure you wish to leave?", pendingProductChanges);
    //

    useEffect(() => {
        if(!currentProduct && id) {
            getProduct();
        } else {
            setProductDescription(RichTextEditor.createValueFromString(currentProduct.description || "", 'html'));
            setProductMatComp(RichTextEditor.createValueFromString(currentProduct.materialComposition || "", 'html'));
            getProductData();
        }

        APIFetch("GET", "location")
        .then(result => {
            if(result.ok) {
                setLocations(result.data);
            } else {
                setLocations([]);
            }
        })
        .catch(() => {
            setLocations([]);
        })
    }, []);

    const onDescriptionChange = (v) => {
        setProductDescription(v);
    }

    const onMatCompChange = (v) => {
        setProductMatComp(v);
    }

    const getProduct = () => {
        APIFetch("GET", `product/${currentProduct ? currentProduct.id : id}`)
        .then(result => {
            if(result.ok) {
                setProductDescription(RichTextEditor.createValueFromString(result.data.description || "", 'html'));
                setProductMatComp(RichTextEditor.createValueFromString(result.data.materialComposition || "", 'html'));
                result.data.basePriceIncVAT = result.data.basePrice * VATRate;
                setCurrentProduct(result.data);
                setTags(result.data.tags);
                getProductData();
            } else {
                setMessage("An error occurred when loading the product data.");
            }
        })
        .catch(e => {
            setMessage("An error occurred when loading the product data.");
        });
    }

    const getProductData = () => {
        // Get product variants
        APIFetch("GET", `variant/${currentProduct ? currentProduct.id : id}`)
        .then(result => {
            if(result.ok) {
                var newConfig = {};

                result.data.variations.forEach(v => {
                    newConfig[v.id] = [];
                });

                for(let i = 0; i < result.data.variants.length; i++) {
                    result.data.variants[i].idx = i + 1;
                    result.data.variants[i].cost = parseFloat(result.data.variants[i].cost).toFixed(2);

                    // Get values for each variation and add to config if it doesn't exist
                    result.data.variations.forEach(v => {
                        var match = result.data.variants[i].variations.find(variation => variation.variationId === v.id);
                        if(match && !newConfig[v.id].includes(match.value)) {
                            newConfig[v.id].push(match.value);
                        }
                    })
                }
                setVariants(result.data.variants);
                setSelectedVariants([]);

                if(result.data.variations.length === 2 && result.data.variations[1].name === 'Size') {
                    setVariations(result.data.variations.reverse());
                } else {
                    setVariations(result.data.variations);
                }
                
                Object.keys(newConfig).forEach(key => {
                    newConfig[key] = sortSizes(newConfig[key]);
                    //l.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
                });
                setVariationConfig(newConfig);
                setLoading(false);
            } else {
                setMessage("An error occurred when loading the product data.");
                setLoading(false);
            }
        })
        .catch((e) => {
            setMessage("An error occurred when loading the product data.");
            setLoading(false);
        });
    }

    const updateField = (field, value) => {
        var newProduct = { ...currentProduct };
        newProduct[field] = value;
        setCurrentProduct(newProduct);
        setPendingProductChanges(true);
    }

    const changeVisibility = (value) => {
        APIFetch("POST", `product/${currentProduct.id}/hidden/${value}`)
        .then(result => {
            if(result.ok) {
                var newProduct = { ...currentProduct };
                newProduct.hidden = value.toString() === 'true' ? true : false;
                setCurrentProduct(newProduct);
                setMessage("Successfully set the product visibility.");
            } else {
                setMessage("An error occurred when attempting to change the visibility.");
            }
        })
        .catch(() => {
            setMessage("An error occurred when attempting to change the visibility.");
        })
    }

    const onDelete = () => {
        APIFetch("DELETE", `product/${currentProduct.id}`)
        .then(result => {
            if(result.ok) {
                var newProducts = [...(products || [])];
                newProducts = newProducts.filter(l => l.id !== currentProduct.id);
                setProducts(newProducts);
                setPendingDelete(null);
                setMessage("Product deleted successfully.");
                onBack ? onBack() : navigate(-1);
            } else {
                setMessage("An error occurred when attempting to delete the product.");
            }
        })
        .catch(() => {
            setMessage("An error occurred when attempting to delete the product.");
        })
    }

    const deleteVariant = (variant, newState = null) => {
        if(!variant.id) {
            // Not saved to API, delete it locally
            var newVariants = (newState || variants).filter(v => v !== variant);
            setVariants(newVariants);
            setSelectedVariants([]);
            setPendingVariantDelete(null);

            var newConfig = {};

            variations.forEach(v => {
                newConfig[v.id] = [];
            });

            newVariants.forEach((variant, i) => {
                variant.idx = i + 1;

                variations.forEach(v => {
                    var match = variant.variations.find(variation => variation.variationId === v.id);
                    if(match && !newConfig[v.id].includes(match.value)) {
                        newConfig[v.id].push(match.value);
                    }
                })
            });

            Object.keys(newConfig).forEach(key => {
                newConfig[key] = sortSizes(newConfig[key]);
                // let l = newConfig[key];
                // l.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
            });
            setVariationConfig(newConfig);

            return Promise.resolve(newVariants);
        }

        return APIFetch("DELETE", `variant/${variant.id}`)
        .then(result => {
            if(result.ok) {
                var newVariants = [...(newState || variants)];
                newVariants = newVariants.filter(v => v !== variant);
                setSelectedVariants([]);
                setPendingVariantDelete(null);

                var newConfig = {};

                variations.forEach(v => {
                    newConfig[v.id] = [];
                });

                newVariants.forEach((variant, i) => {
                    variant.idx = i + 1;
                    variants.cost = parseFloat(variant.cost).toFixed(2);

                    variations.forEach(v => {
                        var match = variant.variations.find(variation => variation.variationId === v.id);
                        if(match && !newConfig[v.id].includes(match.value)) {
                            newConfig[v.id].push(match.value);
                        }
                    })
                });

                setVariants(newVariants);

                Object.keys(newConfig).forEach(key => {
                    newConfig[key] = sortSizes(newConfig[key]);
                    // let l = newConfig[key];
                    // l.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
                });
                setVariationConfig(newConfig);

                return newVariants;
            } else {
                setMessage("An error occurred when attempting to delete the variant.");
                return (newState || variants);
            }
        })
        .catch(() => {
            setMessage("An error occurred when attempting to delete the variant.");
            return (newState || variants);
        })
    }

    const deleteSelectedVariants = async () => {
        let fixedVariants = [...selectedVariants];
        let newState = variants;
        for(let i = 0; i < fixedVariants.length; i++) {
            newState = await deleteVariant(fixedVariants[i], newState);
        }
        // Override variants value to fix incorrect UI
        setVariants(newState);
    }

    const saveChanges = async (confirmed = false) => {
        if(!processing) {
            let manufacturerId = null;
            let supplierId = null;
            let categoryId = null;

            if(!currentProduct.categoryName || currentProduct.categoryName.length === 0) {
                setMessage("A product category is required.");
                return;
            }

            if(selectedVariants.length < variants.length && !confirmed && pendingVariantChanges) {
                setConfirmSave(selectedVariants.length);
                return;
            }

            setProcessing(true);

            // Update Category/Manufacturer/Supplier
            var match2 = DataCache.category.find(a => a.name.toLowerCase() === currentProduct.categoryName.toLowerCase());
            if(!match2) {
                setMessage("Chosen category does not exist.");
                setProcessing(false);
                return;
            } else {
                categoryId = match2.id;
            }

            if(currentProduct.manufacturerName && currentProduct.manufacturerName.length > 0) {
                var match = DataCache.manufacturer.find(a => a.name.toLowerCase() === currentProduct.manufacturerName.toLowerCase());
                if(!match) {
                    // Create new data
                    let result = await createItem('manufacturer', { name: currentProduct.manufacturerName });
                    if(!result.data) {
                        setMessage("Failed to create new manufacturer.");
                        setProcessing(false);
                        return;
                    } else {
                        manufacturerId = result.data.id;
                    }
                } else {
                    manufacturerId = match.id;
                }
            }


            if(currentProduct.supplierName && currentProduct.supplierName.length > 0) {
                var match3 = DataCache.supplier.find(a => a.name.toLowerCase() === currentProduct.supplierName.toLowerCase());
                if(!match3) {
                    // Create new data
                    let result = await createItem('supplier', { name: currentProduct.supplierName });
                    if(!result.data) {
                        setMessage("Failed to create new supplier.");
                        setProcessing(false);
                        return;
                    } else {
                        supplierId = result.data.id;
                    }
                } else {
                    supplierId = match3.id;
                }
            }

            // Save images
            mediaRef.current.saveChanges();

            // Save product
            APIFetch("POST", `product/${currentProduct.id}`, { ...currentProduct, basePrice: currentProduct.basePriceIncVATOverride ? (currentProduct.basePriceIncVATOverride / VATRate) : currentProduct.basePriceOverride || currentProduct.basePrice, manufacturerId, categoryId, supplierId, description: productDescription.toString('html'), materialComposition: productMatComp.toString('html') })
            .then(result => {
                if(result.ok) {
                    setPendingProductChanges(false);
                    if(variants && variants.length > 0) {
                        var variantList = [];

                        variants.forEach((originalVariant) => {
                            if(!selectedVariants.includes(originalVariant)) return;
                            // Create formatted variant object
                            var variant = { ...originalVariant };
                            variant.variationValues = variant.variations;
                            delete variant.variations;
            
                            // Convert strings to numbers
                            variant.price = variant.price ? parseFloat(variant.price).toFixed(2) : 0;
                            variant.reducedPrice = variant.reducedPrice ? parseFloat(variant.reducedPrice).toFixed(2) : 0;
                            variant.cost = variant.cost ? parseFloat(variant.cost).toFixed(2) : 0;
                            variant.replenishmentMinimum = variant.replenishmentMinimum ? parseInt(variant.replenishmentMinimum) : 0;

                            variantList.push(variant);
                        });

                        APIFetch("POST", `variant/batch/${currentProduct.id}`, { variants:  [...variantList].sort((a,b) => (parseInt(b.lastY) - parseInt(a.lastY)) || (parseInt(b.lastX) - parseInt(a.lastX)))})
                        .then(result => {
                            if(result.ok) {
                                setPendingVariantChanges(false);
                                result.data.forEach(r => {
                                    var values = r.values;
                                    var match = variants.find(v => v.id === r.variantId || v.idx === r.variantIdx);
                                    if(match) {
                                        match.id = r.variantId;
                                        match.barcode = r.barcode;
                                        values.forEach(v => {
                                            var variationMatch = match.variations.find(variation => variation.variationId === v.variationId);
                                            variationMatch.valueId = v.valueId;
                                        });
                                    }
                                });

                                // Variants have been saved and assigned IDs, update stock now
                                var stockChanges = [];

                                variants.forEach(variant => {
                                    if(!selectedVariants.includes(variant)) return;
                                    if(variant.stock && variant.stock.length > 0) {
                                        variant.stock.forEach(s => {
                                            if(s.adjustment) {
                                                stockChanges.push({ variantId: variant.id, locationId: s.locationId, adjustment: s.adjustment });
                                            }
                                        })
                                    }
                                });

                                // Delete pending deletes
                                if(removedVariantFieldValues.length > 0) {
                                    APIFetch("DELETE", 'variant/values', removedVariantFieldValues)
                                    .then(result => {
                                        if(result.ok) {
                                            setRemovedVariantFieldValues([])
                                        }
                                    })
                                    .catch(e => {
                                    })
                                    .finally(() => {
                                        APIFetch("POST", `stock/adjust`, stockChanges)
                                        .then(result => {
                                            if(result.ok) {
                                                setMessage("All product, variant and stock changes saved successfully.");
                                                setVariants([...variants]);
                                                // Pull updated data
                                                getProductData();
                                                setProcessing(false);

                                                let labelList = [];

                                                stockChanges.forEach(s => {
                                                    let variant = variants.find(v => v.id == s.variantId);
                                                    let remaining = s.adjustment;
                                                    while(remaining > 0) {
                                                        labelList.push({
                                                            variant,
                                                            locationId: s.locationId
                                                        });
                                                        remaining--;
                                                    }
                                                });

                                                if(labelList.length > 0) setNewStockLabels(labelList);

                                                if(result.data.pendingAssignments && result.data.pendingAssignments.length > 0) {
                                                    setPendingAssignments(result.data.pendingAssignments);
                                                }
                                            } else {
                                                setMessage("An error occurred when attempting to save stock changes.");
                                                setProcessing(false);
                                            }
                                        })
                                        .catch(() => {
                                            setMessage("An error occurred when attempting to save stock changes.");
                                            setProcessing(false);
                                        });
                                    })
                                } else {
                                    APIFetch("POST", `stock/adjust`, stockChanges)
                                    .then(result => {
                                        if(result.ok) {
                                            setMessage("All product, variant and stock changes saved successfully.");
                                            setVariants([...variants]);
                                            // Pull updated data
                                            getProductData();
                                            setProcessing(false);

                                            let labelList = [];

                                            stockChanges.forEach(s => {
                                                let variant = variants.find(v => v.id == s.variantId);
                                                let remaining = s.adjustment;
                                                while(remaining > 0) {
                                                    labelList.push({
                                                        variant,
                                                        locationId: s.locationId
                                                    });
                                                    remaining--;
                                                }
                                            });

                                            if(labelList.length > 0) setNewStockLabels(labelList);

                                            if(result.data.pendingAssignments && result.data.pendingAssignments.length > 0) {
                                                setPendingAssignments(result.data.pendingAssignments);
                                            }
                                        } else {
                                            setMessage("An error occurred when attempting to save stock changes.");
                                            setProcessing(false);
                                        }
                                    })
                                    .catch(() => {
                                        setMessage("An error occurred when attempting to save stock changes.");
                                        setProcessing(false);
                                    });
                                }

                                

                            } else if(result.status === 409) {
                                setMessage("One or more new barcodes are already in use.");
                                setProcessing(false);
                            } else {
                                setMessage("An error occurred when attempting to update a variant.");
                            }
                        })
                        .catch((e) => {
                            setMessage("An error occurred when attempting to update a variant.");
                            setProcessing(false);
                        });
                    } else {
                        if(!message) setMessage("All product changes saved successfully.");
                        setProcessing(false);
                    }
                } else {
                    setMessage("An error occurred when attempting to save the product.");
                    setProcessing(false);
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to save the product.");
                setProcessing(false);
            })
        }
        
    }

    const createVariation = () => {
        if(variations.filter(v => v.name.toLowerCase() === newVariationName.toLowerCase()).length > 0) {
            setMessage("This variation already exists.");
            setNewVariationName("");
            setVariationAdd(false);
        } else {
            if(!processing) {
                setProcessing(true);
                APIFetch("POST", `variant/field/${currentProduct.id}/${newVariationName}`)
                .then(result => {
                    if(result.ok) {
                        var newVariations = [...variations];
                        newVariations.push(result.data);

                        if(newVariations.length === 2 && newVariations[1].name === 'Size') {
                            setVariations(newVariations.reverse());
                        } else {
                            setVariations(newVariations);
                        }

                        var newVariants = recursiveTemplateVariants({
                            ...newVariations[0],
                            values: variationConfig[newVariations[0].id] || []
                        }, []);

                        newVariants.forEach((variant, i) => {
                            variant.idx = i + 1;
                            variant.price = 0;
                            variant.reducedPrice = 0;
                            variant.cost = 0;
                        });

                        setVariants(newVariants);
                        setSelectedVariants([]);
                        setNewVariationName("");
                        setVariationAdd(false);
                    } else {
                        setMessage("An error occurred when attempting to add your variation.");
                    }

                    setProcessing(false);
                })
                .catch((e) => {
                    setMessage("An error occurred when attempting to add your variation.");
                    setProcessing(false);
                });
            }
        }
    }

    const updateVariant = (variant, field, value) => {
        var newVariants = [...variants];
        var match = newVariants.find(v => v === variant);
        match[field] = value;
        setVariants(newVariants);
        setPendingVariantChanges(true);
    }

    const updateVariantStock = (variant, location, value) => {
        var newVariants = [...variants];
        var match = newVariants.find(v => v === variant);
        if(match) {
            setPendingVariantChanges(true);
            if(match.stock) {
                var locMatch = match.stock.findIndex(s => s.locationId === location.id);
                if(locMatch > -1) {
                    match.stock[locMatch].adjustment = value;
                } else {
                    match.stock.push({ id: null, locationId: location.id, quantity: 0, adjustment: value });
                }

                setVariants(newVariants);
                return;
            }
            
            match.stock = [{ id: null, locationId: location.id, quantity: 0, adjustment: value }];
            setVariants(newVariants);
        }
    }

    const deleteVariation = (variationId) => {
        if(!processing) {
            setProcessing(true);
            APIFetch("DELETE", `variant/field/${currentProduct.id}/${variationId}`)
            .then(result => {
                if(result.ok) {
                    var newVariations = [...variations];
                    newVariations = newVariations.filter(v => v.id !== variationId);
                    setVariations(newVariations);

                    // Delete variation values from variants
                    // var newVariants = [...variants];
                    // newVariants.forEach(v => {
                    //     var index = v.variations.findIndex(variation => variation.variationId === variationId);
                    //     v.variations.splice(index, 1);
                    // });
                    // setVariants(newVariants);

                    // Wipe variants
                    var newVariants = [];

                    if(newVariations.length == 1) {
                        newVariants = recursiveTemplateVariants({...newVariations[0], values: variationConfig[newVariations[0].id].map(v => { return { value: v } })}, []);
                    }

                    newVariants.forEach((variant, i) => {
                        variant.idx = i + 1;
                    });

                    setVariants(newVariants);
                    setSelectedVariants([]);
                    setPendingVariationDelete(null);
                    setMessage("Variation deleted successfully.");
                } else {
                    setMessage("An error occurred when attempting to delete the variation.");
                }

                setProcessing(false);
            })
            .catch((e) => {
                setMessage("An error occurred when attempting to delete the variation.");
                setProcessing(false);
            })
        }
    }

    const importTemplate = (selected) => {
        if(!selected || selected.length === 0) {
            return;
        }

        if(!currentProduct.hidden) {
            setMessage("The product must be hidden before size scales can be loaded.");
            return;
        }

        let seen = new Set();
        var hasDuplicates = selected.some(function(currentObject) {
            return seen.size === seen.add(currentObject.variationName).size;
        });

        if(hasDuplicates) {
            setMessage("Templates contain conflicting variation names.");
            return;
        }

        if(!processing) {
            setProcessing(true);

            // Delete all existing variations
            APIFetch("DELETE", `variant/field/${currentProduct.id}/all`)
            .then(result => {
                if(result.ok) {
                    setVariants([]);
                    setSelectedVariants([]);
                    setVariations([]);

                    if(selected.length === 1 && selected[0].variationName.toLowerCase() !== 'colour') {
                        selected.push({
                            id: '-100',
                            variationName: "Colour",
                            values: []
                        });
                    }

                    // Create new variations
                    APIFetch("POST", `variant/field/${currentProduct.id}`, selected.map(s => s.variationName ))
                    .then(result => {
                        if(result.ok) {
                            if(result.data.length === 2 && result.data[1].name === 'Size') {
                                setVariations(result.data.reverse());
                            } else {
                                setVariations(result.data);
                            }

                            selected.forEach(s => {
                                var match = result.data.find(d => d.name === s.variationName);
                                s.id = match.id;
                                if(s.values) s.values.forEach(v => delete v.id);
                            });

                            // Create new variants locally
                            // If first variation has no values then either flip or add placeholder
                            if(selected[0].values.length === 0) {
                                if(selected.length > 1 && selected[1].values.length > 0) {
                                    var first = selected[0];
                                    var second = selected[1];
                                    selected[0] = second;
                                    selected[1] = first;
                                } else {
                                    selected[0].values = ['N/A'];
                                }
                            }

                            var newVariants = recursiveTemplateVariants(selected[0], selected.slice(1));
                            var newConfig = {};

                            result.data.forEach(v => {
                                newConfig[v.id] = [];
                            });

                            newVariants.forEach((variant, i) => {
                                variant.idx = i + 1;

                                result.data.forEach(v => {
                                    var match = variant.variations.find(variation => variation.variationId === v.id);
                                    if(match && !newConfig[v.id].includes(match.value)) {
                                        newConfig[v.id].push(match.value);
                                    }
                                })
                            });

                            Object.keys(newConfig).forEach(key => {
                                newConfig[key] = sortSizes(newConfig[key]);
                                // let l = newConfig[key];
                                // l.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
                            });
                            setVariationConfig(newConfig);
                            setVariants(newVariants);
                            setSelectedVariants([]);
                            setPendingVariantChanges(true);
                            setProcessing(false);
                            setImportModal(false);
                        } else {
                            setMessage("An error occurred when attempting to add the new variations.");
                            setProcessing(false);
                            return;
                        }
                    })
                    .catch((e) => {
                        setMessage("An error occurred when attempting to add the new variations.");
                        setProcessing(false);
                        return;
                    });

                } else {
                    setMessage("An error occurred when attempting to delete the existing variations.");
                    setProcessing(false);
                    return;
                }
            })
            .catch(() => {
                setMessage("An error occurred when attempting to delete the existing variations.");
                setProcessing(false);
                return;
            });
        }
        
    }

    const recursiveTemplateVariants = (variation, remainingTemplates) => {
        if(remainingTemplates.length > 0 && remainingTemplates[0].values.length > 0) {
            var fullVariants = [];
            var deeperVariants = recursiveTemplateVariants(remainingTemplates[0], remainingTemplates.slice(1));

            // For each value, add all of the deeper variations to array with additional variation value
            variation.values.forEach(v => {
                let nestedVariants = deeperVariants.map(dV => {
                    let newVariant = { ...dV };
                    newVariant.variations = [ ...newVariant.variations ]
                    newVariant.variations.push({
                        variationId: variation.id,
                        valueId: v.id || null,
                        value: v.value,
                        name: variation.variationName || variation.name
                    });

                    return newVariant;
                });

                fullVariants = fullVariants.concat(nestedVariants);
            });

            return fullVariants;
        } else {
            // Base case - make variant for each value
            return variation.values.map(v => {
                return {
                    variations: [
                        {
                            variationId: variation.id,
                            valueId: v.id || null,
                            value: v.value,
                            name: variation.variationName || variation.name
                        }
                    ]
                }
            })
        }
    }

    const addVariationValue = () => {
       // TODO: Block duplicates
       var newData = {...editingVariation};
       newData.values = [...newData.values]
       newData.values.push({ idx: Math.max(...newData.values.map(v => v.idx)) + 1, value: "", originalValue: null, deleted: false });
       setEditingVariation(newData);
    }

    const updateVariationValue = (i, v) => {
        // TODO: Block duplicates
        var newData = {...editingVariation};
        newData.values = [...newData.values]
        newData.values[i].value = v;
        setEditingVariation(newData);
    }

    const removeVariationValue = (i) => {
        var newData = {...editingVariation};
        newData.values = [...newData.values];
        if(newData.values[i].originalValue) {
            // Keep original fields
            newData.values[i].deleted = true;
        } else {
            // Remove temporary fields
            newData.values.splice(i, 1);
        }
        
        setEditingVariation(newData);
    }

    const restoreVariationValue = (i) => {
        var newData = {...editingVariation};
        newData.values = [...newData.values]
        newData.values[i].deleted = false;
        setEditingVariation(newData);
    }

    const saveEditedVariation = (confirmed = false) => {
        var variationId = editingVariation.variation.id;
        var newConfig = {...variationConfig};
        newConfig[variationId] = editingVariation.values.map(v => v.deleted ? null : v.value);
        newConfig[variationId] = newConfig[variationId].filter(v => v !== null);

        var removedValues = [];
        var updatedValues = { before: [], after: [] };
        var newValues = [];

        editingVariation.values.forEach(v => {
            if(v.deleted && !newConfig[variationId].includes(v.value)) {
                // Deleted AND hasn't been overwritten by a new/updated entry
                removedValues.push(v.value);
            } else if(v.originalValue && v.value !== v.originalValue) {
                updatedValues.before.push(v.originalValue);
                updatedValues.after.push(v.value);
            } else if(!v.originalValue && (!variationConfig[variationId] || !variationConfig[variationId].includes(v.value))) {
                newValues.push(v.value);
            }
        });

        var deleteCount = 0;
        var updateCount = 0;

        variants.forEach(v => {
            let variation = v.variations.find(v => v.variationId === variationId);
            if(variation && removedValues.includes(variation.value)) {
                deleteCount++;
            } else if(variation) {
                let updateIndex = updatedValues.before.indexOf(variation.value);
                if(updateIndex > -1) {
                    updateCount++;
                }
            }
        });

        var newCount = 0;

        if(newValues.length > 0) {
            newCount = newValues.length;

            Object.keys(newConfig).forEach(id => {
                if(id !== variationId) {
                    newCount *= newConfig[id].length;
                }
            });
        }

        if(confirmed) {
            var newVariants = [...variants];
            var deletedValues = [];

            // Delete
            newVariants = newVariants.filter(v => {
                // Delete variants that were linked to a deleted value
                let value = v.variations.find(variation => variation.variationId === variationId);
                if(value && removedValues.includes(value.value)) {
                    deletedValues.push(value.valueId);
                    return false;
                }

                // Delete variants that were previously missing 
                if(!value) return false;
                
                return true;
            });

            setRemovedVariantFieldValues([...removedVariantFieldValues, ...deletedValues]);
    
            // Update
            newVariants.forEach((v, i) => {
                let value = v.variations.find(variation => variation.variationId === variationId);
                if(value) {
                    let index = updatedValues.before.indexOf(value.value);
    
                    if(index > -1) {
                        value.value = updatedValues.after[index];
                    }
                }
            });
                    
            // Create
            var additionalVariants = [];

            if(newValues.length > 0) {
                additionalVariants = recursiveTemplateVariants({
                        values: newValues.map(v => { return { value: v }}),
                        id: variationId,
                        variationName: editingVariation.variation.name
                }, Object.keys(newConfig).filter(k => k !== variationId).map(key => {
                    return {
                        values: newConfig[key].map(v => { return { value: v } }),
                        id: key,
                        variationName: variations.find(v => v.id === key).name
                    }
                }));
            }
    
            Object.keys(newConfig).forEach(key => {
                newConfig[key] = sortSizes(newConfig[key]);
                // let l = newConfig[key];
                // l.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
            });
            setVariationConfig(newConfig);
            newVariants = newVariants.concat(additionalVariants);
            // Set idx values to cause re-render with new values
            var startIdx = 1;//Math.max(...newVariants.map(v => v.idx)) + 1;

            newVariants.forEach((v, i) => {
                v.idx = startIdx + i;
            });

            setVariants(newVariants);
            setSelectedVariants([]);
            setEditingVariation(null);
            setPendingVariantChanges(true);
        } else {
            setVariationEditConfirmation({ new: newCount, update: updateCount, delete: deleteCount })
        }
    }

    const openMissingVariants = () => {
        var currentShortStrings = [];
        variants.forEach(variant => {
            var variationValues = [...variant.variations];

            variationValues.sort((a,b) => (a.variationId > b.variationId) ? 1 : ((b.variationId > a.variationId) ? -1 : 0));

            let shortString = variationValues.reduce((accumulator, object) => {
                return accumulator + object.value;
            }, '');

            currentShortStrings.push(shortString);
        });

        var missingVariants = [];

        var variantsParam = Object.keys(variationConfig).map(key => {
            return {
                values: variationConfig[key].map(v => { return { value: v } }),
                id: key,
                variationName: variations.find(v => v.id === key).name
            }
        });

        var allVariants = recursiveTemplateVariants(variantsParam[0], variantsParam.slice(1));

        allVariants.forEach(v => {
            v.variations.sort((a,b) => (a.variationId > b.variationId) ? 1 : ((b.variationId > a.variationId) ? -1 : 0));

            let shortString = v.variations.reduce((accumulator, object) => {
                return accumulator + object.value;
            }, '');

            if(!currentShortStrings.includes(shortString)) {
                missingVariants.push(v);
            }
        });

        setMissingModal(missingVariants);
    }

    const addMissingVariants = (additional) => {
        if(additional.length > 0) {
            var newVariants = [...variants];
            var startIdx = newVariants.length > 0 ? Math.max(...newVariants.map(v => v.idx)) + 1 : 1;

            additional.forEach((v, i) => {
                newVariants.push({ idx: startIdx + i, ...v  });
            });
            
            setVariants(newVariants);
            setPendingVariantChanges(true);
            //setSelectedVariants(newVariants);
        }

        setMissingModal(null);
    }

    const onSelected = (key, selected) => {
        let getNewArray = (key, selected, currentSelection) => {
            if(selected) {
                var index = currentSelection.indexOf(key);
                if(index > -1) {
                    var newSelected = [...currentSelection];
                    newSelected.splice(index, 1);
                    return newSelected;
                }
            } else {
                var index = currentSelection.indexOf(key);

                if(index === -1) {
                    var newSelected = [...currentSelection];
                    newSelected.push(key);
                    return newSelected;
                }
            }

            return currentSelection;
        }

        if(Array.isArray(key)) {
            var newArray = [...selectedVariants];

            key.forEach(s => {
                newArray = getNewArray(s, selected, newArray);
            });

            setSelectedVariants(newArray);
        }
        else {
            setSelectedVariants(getNewArray(key, selected, selectedVariants || []));
        }
    }

    const onSelectAll = () => {
        if(selectedVariants.length === variants.length) {
            setSelectedVariants([])
        } else {
            setSelectedVariants(variants);
        }
    }

    const flipVariations = () => {
        var newVariations = [...variations];
        setVariations(newVariations.reverse());
    }

    const updateSelected = () => {
        if(!costOverride && !replenishmentOverride && !priceOverride) return;

        selectedVariants.forEach((v) => {
            if(priceOverride) {
                if(priceOverride > 0) {
                    v.price = (priceOverride / VATRate).toFixed(2);
                    v.reducedPrice = (priceOverride / VATRate).toFixed(2);
                    delete v.basePriceIncVAT;
                } else if(parseFloat(priceOverride) === 0) {
                    delete v.price;
                    delete v.reducedPrice;
                    delete v.basePriceIncVAT;
                }
            }
            if(costOverride) v.cost = costOverride;
            if(replenishmentOverride) v.replenishmentMinimum = replenishmentOverride;
        });

        setCostOverride("");
        setPriceOverride("");
        setReplenishmentOverride("");
        setVariants([...variants]);
        setPendingVariantChanges(true);
    }

    const removeTag = (id) => {
        APIFetch('DELETE', `producttag/${currentProduct.id}/${id}`)
        .then(result => {
            if(result.ok) {
                setTags(tags.filter(t => t.id !== id));
            } else {
                setMessage("An error occurred when attempting to delete the tag.");
            }
        });
    }

    const constructPrintLabels = (selected) => {
        setPrintTypeModal(null);

        var labels = [];
        // For selected add once for each stock
        for(let i = 0; i < selected.length; i++) {
            let variant = selected[i];

            if(variant.stock && variant.stock.length > 0) {
                variant.stock.forEach(s => {
                    let remaining = s.quantity + (s.adjustment || 0);

                    while(remaining > 0) {
                        labels.push({
                            variant,
                            locationId: s.locationId,
                            sortField: variant.variations ? variant.variations.sort((a, b) => a.name.localeCompare(b.name)).map(v => v.value).join(' ') : null
                        });
                        remaining--;
                    }
                })
            }
        }

        labels = sortSizes(labels, 'sortField');

        setPrintLabels(labels);
        setLabelConfigModal(true);
    }
    //{selectedVariants && selectedVariants.length > 0 ? <FontAwesomeIcon icon={faPrint} className="ml-2 text-xl cursor-pointer hover:text-brand-grey-alt" onClick={() => { setPrintTypeModal(true) }}/> : null }
    return (
        <div className="App">
        <div className="absolute top-0 left-0 z-[99]">
            { isPrinting ? <LabelPrint 
                ref={printingRef}
                product={currentProduct}
                labels={printLabels}
                skip={labelSkip}
            /> : null }
            { pendingDelete ? <Modal heading="Confirm Product Deletion" className="" onClose={() => setPendingDelete(null)} windowClassName="w-[450px]">
                <div>Are you sure you wish to delete '{currentProduct.name}'?</div>
                <div className="flex flex-row justify-center mt-8">
                    <div className="flex btn mr-4" onClick={() => setPendingDelete(false)}>Cancel</div>
                    <div className="flex btn" onClick={() => {onDelete()}}>Delete</div>
                </div>
            </Modal> : null }
            { confirmSave !== null ? <Modal heading="Confirm Saving" className="" onClose={() => setConfirmSave(null)} windowClassName="w-[500px]">
                <div className="mb-2">The {confirmSave} selected variants will be saved.</div>
                <div className="mb-4 mt-2 font-semibold">Any changes to any unselected variants will be lost.</div>
                <div>Continue?</div>
                <div className="flex flex-row justify-center mt-8">
                    <div className="flex btn mr-4" onClick={() => setConfirmSave(null)}>Cancel</div>
                    <div className="flex btn" onClick={() => {saveChanges(true); setConfirmSave(null); }}>Save</div>
                </div>
            </Modal> : null }
            { pendingVariationDelete ? <Modal heading="Confirm Variation Deletion" className="" onClose={() => setPendingVariationDelete(null)} windowClassName="w-[450px]">
                <div>Are you sure you wish to delete '{pendingVariationDelete.name}'?</div>
                <div className="flex flex-row justify-center mt-8">
                    <div className="flex btn mr-4" onClick={() => setPendingVariationDelete(false)}>Cancel</div>
                    <div className="flex btn" onClick={() => {deleteVariation(pendingVariationDelete.id)}}>Delete</div>
                </div>
            </Modal> : null }
            { pendingVariantDelete ? <Modal heading="Confirm Variant Deletion" className="" onClose={() => setPendingVariantDelete(null)} windowClassName="w-[450px]">
                <div>Are you sure you wish to delete '{pendingVariantDelete.idx}'?</div>
                <div className="flex flex-row justify-center mt-8">
                    <div className="flex btn mr-4" onClick={() => setPendingVariantDelete(false)}>Cancel</div>
                    <div className="flex btn" onClick={() => {deleteVariant(pendingVariantDelete.id, pendingVariantDelete.idx)}}>Delete</div>
                </div>
            </Modal> : null }
            { variationAdd ? <Modal heading="Add New Variation" className="" onClose={() => setVariationAdd(false)} windowClassName="w-[450px]">
                <div className="flex flex-row items-center">
                    <div className="mr-2">Name: </div>
                    <input type="text" value={newVariationName} className="rounded p-1 px-2 text-black border border-brand flex-1" onChange={(e) => setNewVariationName(e.target.value)}/>
                </div>
                <div className="flex flex-row justify-center mt-8">
                    <div className="flex btn mr-4" onClick={() => setVariationAdd(false)}>Cancel</div>
                    <div className="flex btn" onClick={() => {createVariation()}}>Confirm</div>
                </div>
            </Modal> : null }
            { editingVariation ? <Modal onClose={() => setEditingVariation(null)} heading="Edit Variation" className="text-left" windowClassName="w-[450px]">
                    <div className="flex flex-row items-center mb-2">
                        <div className="mr-2">Variation Values: </div>
                        <FontAwesomeIcon icon={faPlus} className="cursor-pointer hover:text-brand-grey-alt relative top-[-1px]" onClick={() => { addVariationValue() }}/>
                    </div>
                    <div className="overflow-y-auto pr-4">
                        {editingVariation.values.map((v,i) => {
                            return <div className="flex flex-row items-center mb-2">
                                <input key={v} type="text" className={"flex-1 mr-2" + (v.deleted ? " line-through" : "")} value={v.value} onChange={(e) => { updateVariationValue(i, e.target.value); }} />
                                { v.deleted ? <FontAwesomeIcon icon={faUndo} className="cursor-pointer hover:text-brand-grey-alt" onClick={() => { restoreVariationValue(i) }}/> : <FontAwesomeIcon icon={faTimes} className="cursor-pointer hover:text-brand-grey-alt" onClick={() => { removeVariationValue(i) }}/>}
                            </div>
                        })}
                    </div>
                <div className="flex flex-row justify-center mt-8">
                    <div className="flex btn mr-4" onClick={() => setEditingVariation(null)}>Cancel</div>
                    <div className="flex btn" onClick={() => saveEditedVariation()}>Save</div>
                </div>
            </Modal> : null }
            { variationEditConfirmation ? <Modal heading="Confirm Variation Edit" className="" onClose={() => setVariationEditConfirmation(null)} windowClassName="w-[450px]">
                <div>Are you sure you wish to apply these changes?</div>
                {variationEditConfirmation.delete ? <div className="font-semibold mt-4">{`${variationEditConfirmation.delete} variants will be deleted.`}</div> : null}
                {variationEditConfirmation.update ? <div className="font-semibold mt-4">{`${variationEditConfirmation.update} variants will be updated.`}</div> : null}
                {variationEditConfirmation.new ? <div className="font-semibold mt-4">{`${variationEditConfirmation.new} variants will be created.`}</div> : null}
                <div className="flex flex-row justify-center mt-8">
                    <div className="flex btn mr-4" onClick={() => setVariationEditConfirmation(null)}>Cancel</div>
                    <div className="flex btn"  onClick={() => { saveEditedVariation(true); setVariationEditConfirmation(null) }}>Confirm</div>
                </div>
            </Modal> : null }
            { printTypeModal ? <Modal heading="Select Print Type" className="" onClose={() => setPrintTypeModal(null)} windowClassName="w-[450px]">
                <div>Which labels would you like to print?</div>
                <div className="text-center border my-2 py-1 cursor-pointer" onClick={() => constructPrintLabels(variants)}>
                    All
                </div>
                <div className="text-center border my-2 py-1 cursor-pointer" onClick={() => constructPrintLabels(selectedVariants)}>
                    Selected
                </div>
                <div className="flex flex-row justify-center mt-8">
                    <div className="flex btn mr-4" onClick={() => setPrintTypeModal(null)}>Cancel</div>
                </div>
            </Modal> : null }
            { importModal ? <TemplateImportModal onClose={() => { setImportModal(false) }} onImport={importTemplate}/> : null }
            { missingModal ? <MissingVariantModal onClose={() => { setMissingModal(null) }} onAdd={addMissingVariants} missingVariants={missingModal}/> : null }
            { newStockLabels ? <LabelPrintingModal ids={newStockLabels} onClose={() => setNewStockLabels(null)} onSuccess={() => { setPrintLabels(newStockLabels); setNewStockLabels(null); setLabelConfigModal(true); }} /> : null }
            { pendingAssignments ? <PendingAssignmentsModal onClose={() => setPendingAssignments(null)} onSuccess={() => { setMessage("Stock assigned successfully."); setPendingAssignments(null); getProductData();} } pendingAssignments={pendingAssignments}/> : null}
            {addingTag ? <AddTagModal productId={currentProduct.id} onClose={() => setAddingTag(false)} onAdd={(tag) => { 
                if(tag) { 
                    setTags([...tags, tag]);
                    DataCache.tag.push(tag);
                    DataCache.tagLookup[tag.id] = tag;
                };
                setAddingTag(false); }
            }/> : null}
            { processing || loading ? <OverlaySpinner /> : null }
            {labelConfigModal ? <LabelConfigModal items={printLabels} onClose={() => { setLabelConfigModal(false); }} onSuccess={(result) => { setLabelConfigModal(false); setLabelSkip(result.skipCount); handlePrint(); }}/> : null }
            <MessageModal message={message} onClose={() => setMessage(null)} />
        </div>
        <div className="flex flex-row w-full h-full overflow-y-hidden">
            <Sidebar />
            <div className="content overflow-y-scroll flex flex-col p-2 md:p-8 flex-grow text-left overflow-auto">
                <div className="flex flex-row items-center ml-2 mt-16 mb-16">
                    <FontAwesomeIcon icon={onBack ? faTimes : faChevronLeft} className="text-xl mr-3 mb-1 cursor-pointer hover:text-brand-grey-alt relative top-[1px]" onClick={() => onBack ? onBack() : navigate(-1)}/>
                    <h1 className="text-left font-medium text-lg">{currentProduct ? currentProduct.name : "Loading product..."}</h1>
                    <FontAwesomeIcon icon={faSave} className="ml-auto text-lg mr-4 cursor-pointer hover:text-brand-grey-alt" onClick={() => saveChanges()}/>
                    <FontAwesomeIcon icon={faTrash} className="text-lg mr-4 cursor-pointer hover:text-brand-grey-alt" onClick={() => setPendingDelete(true)}/>
                </div>
                {currentProduct ? <div className="flex flex-row pt-1 flex-wrap lg:flex-nowrap">
                    <div className="flex flex-col justify-start p-4 flex-1 m-2 basis-3/4">
                        <h2 className="font-bold mb-2">Overview</h2>
                        <div className="text-left text-sm mt-2">Title</div>
                        <input type="text" value={currentProduct.name} className="p-1 px-2 border-b border-brand-grey" onChange={(e) => { updateField('name', e.target.value) }}/>
                        <div className="text-left text-sm mt-2">Description</div>
                        <RichTextEditor value={productDescription} onChange={onDescriptionChange}/>
                        <div className="text-left text-sm mt-2">Product Code</div>
                        <input type="text" value={currentProduct.productCode} className="p-1 px-2 border-b border-brand-grey" onChange={(e) => { updateField('productCode', e.target.value) }}/>
                        {/* <div className="text-left text-sm mt-2">Material Type</div>
                        <input type="text" value={currentProduct.materialType} className="p-1 px-2 border-b border-brand-grey" onChange={(e) => { updateField('materialType', e.target.value) }}/>
                        <div className="text-left text-sm mt-2">Material Composition</div>
                        <RichTextEditor value={productMatComp} onChange={onMatCompChange}/> */}
                        <div className="text-left text-sm mt-2">Default Price</div>
                        <input type="number" placeholder={((currentProduct.basePriceOverride || currentProduct.basePrice) * VATRate).toFixed(2)} value={currentProduct.basePriceIncVATOverride} className="p-1 px-2 border-b border-brand-grey" onChange={(e) => { updateField('basePriceIncVATOverride', e.target.value) }}/>
                        {/* <div className="text-left text-sm mt-2">Base Price (Ex-VAT)</div>
                        <input type="number" placeholder={(currentProduct.basePriceIncVATOverride ? currentProduct.basePriceIncVATOverride / VATRate : currentProduct.basePrice).toFixed(2)} value={currentProduct.basePriceOverride} className="p-1 px-2 border-b border-brand-grey" onChange={(e) => { updateField('basePriceOverride', e.target.value) }}/> */}
                        <div className="text-left text-sm mt-2">Default Cost</div>
                        <input type="number" value={currentProduct.baseCostPrice} className="p-1 px-2 border-b border-brand-grey" onChange={(e) => { updateField('baseCostPrice', e.target.value) }}/>
                    </div>
                    <div className="flex flex-col justify-start p-4 m-2 mb-auto flex-grow min-w-[25%]">
                        <h2 className="font-bold mb-2">Categorisation</h2>
                        <div className="text-left text-sm mt-2">Visibility</div>
                        <select className="p-1 px-1 border-b border-brand-grey" value={currentProduct.hidden} onChange={(e) => { changeVisibility(e.target.value) }}>
                            <option value={true}>Hidden</option>
                            <option value={false}>Visible</option>
                        </select>
                        <div className="text-left text-sm mt-2">Category</div>
                        <LookupInput className="flex flex-1" itemType={'category'} initialValue={currentProduct.categoryName} onChange={(value) => { updateField('categoryName', value) }}/>
                        <div className="text-left text-sm mt-2">Supplier</div>
                        <LookupInput className="flex flex-1" itemType={'supplier'} initialValue={currentProduct.supplierName} onChange={(value) => { updateField('supplierName', value) }}/>
                        <div className="text-left text-sm mt-2">Manufacturer</div>
                        <LookupInput className="flex flex-1" itemType={'manufacturer'} initialValue={currentProduct.manufacturerName} onChange={(value) => { updateField('manufacturerName', value) }}/>
                        <div className="text-left text-sm mt-2">Tags<FontAwesomeIcon icon={faPlus} className="ml-2 text-sm cursor-pointer hover:text-brand-grey-alt" onClick={() => { setAddingTag(true) }}/></div>
                        <div className="flex flex-row flex-wrap">
                            {tags ? tags.map(t => {
                                return <div className="flex flex-row items-center rounded-2xl bg-white border border-brand-grey text-brand-grey px-3 py-1 font-medium text-sm mr-2 mt-2">
                                    <span className="mt-[2px] pr-2 mr-2 border-r border-r-black">{t.category}</span>
                                    <span className="mt-[2px]">{t.name}</span>
                                    <FontAwesomeIcon icon={faTimes} className="ml-2 text-sm cursor-pointer hover:text-brand-grey-alt" onClick={() => removeTag(t.id)}/>
                                </div>
                            }) : null}
                        </div>
                    </div>
                </div> : null }
                <div className="border border-brand-grey py-2 px-8 rounded-full cursor-pointer text-sm mr-auto ml-6" onClick={() => saveChanges()}>Save All</div>
                <div className="flex flex-row flex-1 m-2">
                    <div className="flex flex-col flex-1 justify-start p-4 max-w-full">
                        <div className="flex flex-row items-center mb-2">
                            <h2 className="font-bold">Variants</h2>
                            <FontAwesomeIcon icon={faFileImport} className="ml-2 cursor-pointer hover:text-brand-grey-alt" onClick={() => { setImportModal(true); }}/>
                            <FontAwesomeIcon icon={faPlus} className="ml-2 cursor-pointer hover:text-brand-grey-alt" onClick={() => { openMissingVariants(); }}/>
                            <FontAwesomeIcon icon={faSave} className={"ml-2 cursor-pointer hover:text-brand-grey-alt"} onClick={() => { saveChanges(); }}/>
                            <FontAwesomeIcon icon={faPrint} className="ml-2 cursor-pointer hover:text-brand-grey-alt" onClick={() => { setPrintTypeModal(true) }}/>
                            {selectedVariants && selectedVariants.length > 0 ? <div className="ml-auto flex flex-row items-center">
                                <div className="flex flex-row items-center text-sm">
                                    <div className="mr-1">Replenishment Minimum:</div>
                                    <input type="number" value={replenishmentOverride} className="p-1 px-2 border-b border-brand-grey mr-4 w-[90px]" onChange={(e) => { setReplenishmentOverride(e.target.value) }}/>
                                    <div className="mr-1">Cost:</div>
                                    <input type="number" value={costOverride} className="p-1 px-2 border-b border-brand-grey mr-4 w-[90px]" onChange={(e) => { setCostOverride(e.target.value) }}/>
                                    <div className="mr-1">Price:</div>
                                    <input type="number" value={priceOverride} className="p-1 px-2 border-b border-brand-grey mr-4 w-[90px]" onChange={(e) => { setPriceOverride(e.target.value) }}/>
                                    <div className="border border-brand-grey py-1 px-4 rounded-full cursor-pointer text-sm" onClick={updateSelected}>Set Selected</div>
                                </div>
                                <div className="ml-4 text-sm font-medium">{`${selectedVariants.length} Variant${selectedVariants.length > 1 ? 's' : ''} Selected`}</div>
                            </div> : null }
                            {selectedVariants && selectedVariants.length > 0 ? <FontAwesomeIcon icon={faTrash} className="ml-2 text-xl cursor-pointer hover:text-brand-grey-alt" onClick={() => { deleteSelectedVariants(); }}/> : null }
                        </div>
                        <div className="flex flex-row">
                            { variationConfig && variations && variations.length > 0 ? <div className="flex flex-row items-center rounded-2xl bg-white border border-brand-grey text-brand-grey px-3 py-1 font-medium text-sm mr-2" key={variations[0].id}>
                                    <span className="mt-[2px]">{variations[0].name} ({variationConfig[variations[0].id] ? variationConfig[variations[0].id].length : 0})</span>
                                    <FontAwesomeIcon icon={faEdit} className="ml-2 text-sm cursor-pointer hover:text-brand-grey-alt" onClick={() => { setEditingVariation({ variation: variations[0], values: variationConfig[variations[0].id] ? variationConfig[variations[0].id].map((v,i) => { return { id: i, value: v, originalValue: v, deleted: false } }): [], variants: [...variants] })}}/>
                                    <FontAwesomeIcon icon={faTimes} className="ml-2 text-sm cursor-pointer hover:text-brand-grey-alt" onClick={() => { setPendingVariationDelete({ id: variations[0].id, name: variations[0].name }) }}/>
                                </div> : null }
                            { variationConfig && variations && variations.length > 1 ?  <FontAwesomeIcon icon={faRightLeft} className="mx-2 text-sm cursor-pointer self-center" onClick={() => { flipVariations() }}/> : null }
                            { variationConfig && variations && variations.length > 1 ? <div className="flex flex-row items-center rounded-2xl bg-white border border-brand-grey text-brand-grey px-3 py-1 font-medium text-sm" key={variations[1].id}>
                                    <span className="mt-[2px]">{variations[1].name} ({variationConfig[variations[1].id] ? variationConfig[variations[1].id].length : 0})</span>
                                    <FontAwesomeIcon icon={faEdit} className="ml-2 text-sm cursor-pointer hover:text-brand-grey-alt" onClick={() => { setEditingVariation({ variation: variations[1], values: variationConfig[variations[1].id] ? variationConfig[variations[1].id].map((v,i) => { return { id: i, value: v, originalValue: v, deleted: false } }): [], variants: [...variants] })}}/>
                                    <FontAwesomeIcon icon={faTimes} className="ml-2 text-sm cursor-pointer hover:text-brand-grey-alt" onClick={() => { setPendingVariationDelete({ id: variations[1].id, name: variations[1].name }) }}/>
                                </div> : null }
                            { variationConfig && variations && variations.length < 2 ? <div className="flex flex-row items-center rounded-2xl bg-white border border-brand-grey text-brand-grey px-3 py-1 mr-2 cursor-pointer font-medium text-sm"  onClick={() => { setVariationAdd(true) }}>
                                <FontAwesomeIcon icon={faPlus} className="mr-2 text-md hover:text-brand-grey-alt"/>
                                <span>Add variation type</span>
                            </div> : null }
                        </div>
                        {variationConfig && variations && variations.length === 1 ? <StockGrid product={currentProduct} xAxisId={variations[0].id} xAxisValues={variationConfig[variations[0].id] || []} yAxisId={null} yAxisValues={[]} variants={variants} locations={locations} onVariantChange={updateVariant} onStockChange={updateVariantStock} onSelect={onSelected} selected={selectedVariants} onToggleAll={onSelectAll}/> : null }
                        {variationConfig && variations && variations.length > 1 ? <StockGrid product={currentProduct} xAxisId={variations[0].id} xAxisValues={variationConfig[variations[0].id] || []} yAxisId={variations[1].id} yAxisValues={variationConfig[variations[1].id]} variants={variants} locations={locations} onVariantChange={updateVariant} onStockChange={updateVariantStock} onSelect={onSelected} selected={selectedVariants} onToggleAll={onSelectAll}/> : <div>Add a variation above to create product variants.</div> }
                    </div>
                </div>
                <div className="flex flex-row m-2">
                    {currentProduct && currentProduct.id ? <MediaSection ref={mediaRef} productId={currentProduct.id} product={currentProduct} variations={variations} /> : null }
                </div>
            </div>
        </div>
        </div>
    );
}

export default ProductDetails;